import { IAnimationStateListener, Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS_ANIMATIONS, SlotId } from '../../../config';
import { isScatter } from '../../../utils';
import Animation from '../../animations/animation';

import { IAnimateSlot } from './IAnimateSlot';
import {
  BASE_SLOT_SPINE_ANIMATION_PRIORITY,
  SCATTER_SPINE_ANIMATION_PRIORITY,
  WILD_SPINE_ANIMATION_PRIORITY,
} from './config';

export class SpineAnimateSlot extends PIXI.Container implements IAnimateSlot {
  public slotId: SlotId;

  private spine: Spine;

  private positionPriority = 0;

  constructor(slotId: SlotId, positionPriority: number) {
    super();

    this.slotId = slotId;
    this.positionPriority = positionPriority;

    this.spine = new Spine(PIXI.Loader.shared.resources[MAPPED_SYMBOLS_ANIMATIONS[slotId].src!]!.spineData!);
    this.spine.visible = false;

    this.addChild(this.spine);
  }

  private startWinAnimation() {
    this.idle = false;
    this.spine.state.setAnimation(0, MAPPED_SYMBOLS_ANIMATIONS[this.slotId].win!, false);
    this.spine.state.addEmptyAnimation(0, 0, 0);
    this.zIndex = this.getPriority();
  }

  private getPriority(): number {
    if (isScatter(this.slotId)) {
      return SCATTER_SPINE_ANIMATION_PRIORITY + this.positionPriority;
    } else if (this.slotId === SlotId.WL) {
      return WILD_SPINE_ANIMATION_PRIORITY + this.positionPriority;
    }
    return BASE_SLOT_SPINE_ANIMATION_PRIORITY + this.positionPriority;
  }

  public startStopAnimation(): void {
    this.zIndex = BASE_SLOT_SPINE_ANIMATION_PRIORITY;
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration =
      (this.spine.skeleton.data.findAnimation(MAPPED_SYMBOLS_ANIMATIONS[this.slotId].win!)?.duration ?? 0) * 1000;

    const listener: IAnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      PIXI.Ticker.shared.addOnce(() => {
        this.zIndex = 10;
        this.startWinAnimation();
        this.spine.state.addListener(listener);
      });
    });

    animation.addOnSkip(() => {
      this.idle = true;
      this.spine.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.idle = true;
      this.spine.state.removeListener(listener);
    });
    return animation;
  }

  public skip(): void {
    this.idle = true;
  }

  public set idle(value: boolean) {
    this.spine.visible = !value;
    this.visible = this.spine.visible;
    this.zIndex = value ? 0 : 10;
  }

  public set tint(value: number) {
    this.spine.tint = value;
  }
}
