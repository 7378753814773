import * as PIXI from 'pixi.js';

import { MAPPED_BLURRED_SYMBOLS, MAPPED_SYMBOLS, SlotId } from '../../config';
import { REEL_HEIGHT } from '../config';

export class Slot extends PIXI.Sprite {
  private id: number;

  public slotId: SlotId;

  public textureSlotId: SlotId;

  constructor(id: number, slotId: SlotId) {
    super(PIXI.Texture.from(MAPPED_SYMBOLS[slotId]));
    this.id = id;
    this.slotId = slotId;
    this.textureSlotId = slotId;
    this.y = REEL_HEIGHT * id;
    this.anchor.set(0, 1);
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[slotId]);
    this.textureSlotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      this.texture = PIXI.Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId]);
    } else {
      this.texture = PIXI.Texture.from(MAPPED_SYMBOLS[this.textureSlotId]);
    }
  }

  public onSlotStopped(): void {
    // todo add sound/animation on slot stop
  }
}
