import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { ResourceTypes } from '../../resources';

export type IButtonTexture = {
  default: ResourceTypes;
  hover: ResourceTypes;
  press: ResourceTypes;
  disable: ResourceTypes;
};

export class SpriteButton extends PIXI.Sprite {
  private _disable: boolean;

  private textures: IButtonTexture;

  private isPointerDown = false;

  constructor(textures: IButtonTexture, onClick?: PIXI.utils.EventEmitter.ListenerFn) {
    super(PIXI.Texture.from(textures.default));

    this.textures = textures;
    this._disable = false;
    this.interactive = true;
    this.anchor.set(0.5);

    this.on('pointerdown', () => {
      this.isPointerDown = true;
      this.texture = PIXI.Texture.from(textures.press);
    });
    this.on('mouseover', (_: string) => {
      if (this.isPointerDown) {
        return;
      }
      AudioApi.play({ type: ISongs.SONG_SFX_UI_Hover });
      this.texture = PIXI.Texture.from(textures.hover);
    });
    this.on('pointerupoutside', () => {
      this.isPointerDown = false;
      this.texture = PIXI.Texture.from(textures.default);
    });
    this.on('pointerout', () => {
      if (this.isPointerDown) {
        return;
      }
      this.texture = PIXI.Texture.from(textures.default);
    });
    this.on('pointertap', (e: PIXI.InteractionEvent) => {
      this.isPointerDown = false;
      this.texture = PIXI.Texture.from(textures.default);
      if (e.data.isPrimary) {
        onClick?.call(null);
      }
    });
  }

  public set disable(value: boolean) {
    this._disable = value;
    this.interactive = !value;

    if (value) {
      this.texture = PIXI.Texture.from(this.textures.disable);
    } else {
      this.texture = PIXI.Texture.from(this.textures.default);
    }
  }

  public get disable(): boolean {
    return this._disable;
  }
}
