import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode } from '../../utils';
import ViewContainer from '../components/container';
import {
  GAME_TITLE_LOGO_LANDSCAPE_X_POS,
  GAME_TITLE_LOGO_LANDSCAPE_Y_POS,
  GAME_TITLE_LOGO_PORTRAIT_X_POS,
  GAME_TITLE_LOGO_PORTRAIT_Y_POS,
  eventManager,
} from '../config';

class GameTitle extends ViewContainer {
  private titleLogo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));

  private window = { width: 0, height: 0 };

  constructor() {
    super();

    this.initGameLogo();
    this.addChild(this.titleLogo);

    eventManager.addListener(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) => {
      this.changeVisible(settings.mode);
    });
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, (settings: { mode: GameMode }) => {
      this.changeVisible(settings.mode);
    });
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
  }

  private initGameLogo(): void {
    this.titleLogo.anchor.set(0.5);
  }

  private changeVisible(mode: GameMode) {
    this.visible = !isFreeSpinMode(mode);
  }

  private resize(width: number, height: number): void {
    this.window = { width, height };
  }

  private gameContainerResize(_width: number, _height: number, _x: number, _y: number, _scale: number) {
    if (this.window.width > this.window.height) {
      this.titleLogo.x = GAME_TITLE_LOGO_LANDSCAPE_X_POS;
      this.titleLogo.y = GAME_TITLE_LOGO_LANDSCAPE_Y_POS;
    } else {
      this.titleLogo.x = GAME_TITLE_LOGO_PORTRAIT_X_POS;
      this.titleLogo.y = GAME_TITLE_LOGO_PORTRAIT_Y_POS;
    }
  }
}

export default GameTitle;
