import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_ANIMATIONS, SlotId } from '../../../config';
import Animation from '../../animations/animation';
import SpriteAnimation from '../../animations/sprite';
import { APPLICATION_FPS, SPRITE_ANIMATION_FPS } from '../../config';

import { IAnimateSlot } from './IAnimateSlot';

export class SpriteSheetSlot extends PIXI.Container implements IAnimateSlot {
  public slotId: SlotId;

  private sprite: PIXI.Sprite;

  constructor(slotId: SlotId) {
    super();

    this.slotId = slotId;
    this.sprite = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[slotId]));
    this.sprite.anchor.set(0.5, 0.5);
    this.sprite.visible = true;

    this.addChild(this.sprite);
  }

  public set idle(value: boolean) {
    this.sprite.visible = value;
  }

  public get idle(): boolean {
    return this.sprite.visible;
  }

  public set tint(value: number) {
    this.sprite.tint = value;
  }

  public startStopAnimation(): void {
    this.idle = true;
  }

  public getWinAnimation(): Animation {
    const animationData = MAPPED_SYMBOLS_ANIMATIONS[this.slotId]!;
    const resource = PIXI.Loader.shared.resources[animationData.src!];
    const spriteAnimation = new SpriteAnimation({}, Object.values(resource!.spritesheet!.textures));

    spriteAnimation.spriteAnimation.animationSpeed = SPRITE_ANIMATION_FPS / APPLICATION_FPS;

    spriteAnimation.addOnStart(() => {
      this.idle = false;
      this.addChild(spriteAnimation.spriteAnimation);
    });

    spriteAnimation.addOnSkip(() => {
      this.idle = true;
      this.removeChild(spriteAnimation.spriteAnimation);
    });

    spriteAnimation.addOnComplete(() => {
      this.idle = true;
      this.removeChild(spriteAnimation.spriteAnimation);
    });

    return spriteAnimation;
  }

  public skip(): void {
    this.idle = true;
  }
}
