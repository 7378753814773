import * as PIXI from 'pixi.js';

import { Variables } from '../../config';
import { EventTypes, UserBonus } from '../../global.d';
import i18n from '../../i18next';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, eventManager } from '../config';

const textStyle = {
  fontFamily: Variables.FONT_FAMILY,
  ...{
    fill: ['#e7194b', '#f19e00', '#feff17', '#f19e00', '#e7194b'],
    fillGradientStops: [0.3, 0.5, 0.6, 0.7],
    fillGradientType: 0,
    stroke: '#111111',
    dropShadow: true,
    dropShadowAlpha: 1.0,
    dropShadowAngle: 1.0,
    dropShadowBlur: 2,
    dropShadowColor: 0x333333,
    dropShadowDistance: 3,
  },
  fontSize: 120,
  miterLimit: 0,
  strokeThickness: 4,
};

export class AddFreeSpinsCounter extends ViewContainer {
  private addCount: PIXI.Text;

  constructor() {
    super();
    this.addCount = this.init();
    this.addChild(this.addCount);
    this.x = GAME_CONTAINER_WIDTH / 2;
    this.y = GAME_CONTAINER_HEIGHT / 2 + 200;

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.visible = false;
    });
    eventManager.addListener(EventTypes.ADD_FREE_SPINS, this.show.bind(this));
  }

  private init(): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>(''), textStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.scale.set(1.5);
    this.visible = false;
    return text;
  }

  private show(_: UserBonus, addRounds: number) {
    const addAnimation = Tween.createDelayAnimation(0);
    addAnimation.addOnComplete(() => {
      this.addCount.text = i18n.t<string>('addFreeSpinWithCount', { count: addRounds });
      this.visible = true;
    });

    addAnimation.start();
  }
}
