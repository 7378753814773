import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, PopupOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenInfoPopup,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode, updateTextScale } from '../../utils';
import { UiViewContainer } from '../components/uiContainer';
import {
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';
import AutoResizeText from '../text/autoResizeText';

class BuyFeatureBtn extends UiViewContainer {
  private button: PIXI.Sprite;

  private text: PIXI.Text;

  private isDisabled: boolean;

  private isFreeRoundBonus: boolean;

  private window = { width: 0, height: 0 };

  constructor() {
    super();
    this.isDisabled = false;
    this.visible = !setBrokenGame();
    this.isFreeRoundBonus = false;

    this.button = this.initBuyFeatureButton();
    this.text = this.initBuyFeatureText();
    this.addChild(this.button, this.text);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.addListener(EventTypes.CHANGE_MODE, ({ mode }) => {
      this.visible = !isFreeSpinMode(mode);
    });
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, ({ mode }) => {
      this.visible = !isFreeSpinMode(mode);
    });
    eventManager.on(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.on(EventTypes.SET_IS_FADEOUT, (isFadeOut: boolean) => {
      this.handleDisable(isFadeOut);
    });

    eventManager.addListener(EventTypes.SET_BROKEN_BUY_FEATURE, (isBroken: boolean) => {
      this.handleDisable(isBroken);
    });
    eventManager.on(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.isFreeRoundBonus = isFreeRoundBonus;
      this.handleDisable(isFreeRoundBonus);
    });
  }

  private initBuyFeatureButton() {
    const button = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    button.anchor.set(0.5);
    button.interactive = true;
    button.on('click', () => this.onClick());
    button.on('touchstart', () => this.onClick());

    button.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SONG_SFX_UI_Hover });
        this.button.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnHover);

        this.text.style = buyFeatureHoverTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      }
    });
    button.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.button.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);

        this.text.style = buyFeatureTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      }
    });
    button.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.button.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnPressed);

        this.text.style = buyFeatureClickTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_PRESS_X_OFFSET, FEATURE_BTN_TEXT_PRESS_Y_OFFSET);
      }
    });
    button.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.button.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);

        this.text.style = buyFeatureTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      }
    });
    return button;
  }

  private initBuyFeatureText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t<string>('buyFeatureBtn'), buyFeatureTextStyle);
    text.anchor.set(0.5);
    text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2 + FEATURE_BTN_TEXT_Y_OFFSET);
    updateTextScale(text, 120, 100);
    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayPopup(false);
      setIsOpenBetSettingsPopup(false);
      setIsOpenInfoPopup(false);
      AudioApi.play({ type: ISongs.SONG_SFX_BuyFeature });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isFreeRoundBonus && !disable) return;
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    if (disable) {
      this.button.buttonMode = false;
      this.button.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnNotActive);
      this.text.style = buyFeatureDisableTextStyle;
    } else {
      this.button.buttonMode = true;
      this.button.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
      this.text.style = buyFeatureTextStyle;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  protected override resize(width: number, height: number): void {
    this.window = { width, height };
    this.handlePosition();
  }

  private handlePosition(): void {
    if (this.window.width >= this.window.height) {
      this.scale.set(1, 1);
      this.x = -this.button.width / 2 - 60;
      this.y = GAME_CONTAINER_HEIGHT - 15;
    } else {
      //this.scale.set(0.8, 0.8);
      this.scale.set(1, 1);
      this.x = GAME_CONTAINER_WIDTH - 100;
      this.y = GAME_CONTAINER_HEIGHT + 200;
    }
  }
}

export default BuyFeatureBtn;
