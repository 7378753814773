export const gameRules = [
  { key: 'infoGameRules1_1', buyFeatureOnly: false },
  { key: 'infoGameRules1_2', buyFeatureOnly: false },
  { key: 'infoGameRules1_3', buyFeatureOnly: false },
  { key: 'infoGameRules1_4', buyFeatureOnly: false },
  { key: 'infoGameRules1_5', buyFeatureOnly: false },
  { key: 'infoGameRules1_6', buyFeatureOnly: false },
  { key: 'infoGameRules1_7', buyFeatureOnly: false },
  { key: 'infoGameRules1_8', buyFeatureOnly: false },
  { key: 'infoGameRules1_9', buyFeatureOnly: false },
  { key: 'infoGameRules1_10', buyFeatureOnly: false },
  { key: 'infoGameRules1_11', buyFeatureOnly: false },
  { key: 'infoGameRules1_12', buyFeatureOnly: false },
];

export const infoFreeSpinFeatureDesc = [
  'infoFeatureFree',
  'infoFreeSpinFeatureDesc_1',
  'infoFreeSpinFeatureDesc_2',
  'infoFreeSpinFeatureDesc_3',
  'infoFreeSpinFeatureDesc_4',
  'infoFreeSpinFeatureDesc_5',
  'infoFreeSpinFeatureDesc_6',
];

export const infoFreeSpinFeatureDesc2 = [
  'infoFreeSpinFeatureDesc_7',
  'infoFreeSpinFeatureDesc_8',
  'infoFreeSpinFeatureDesc_9',
  'infoFreeSpinFeatureDesc_10',
  'infoFreeSpinFeatureDesc_11',
];

export const infoBuyFeatureDesc = [
  'infoBuyFeatureDesc_1',
  'infoBuyFeatureDesc_2',
  'infoBuyFeatureDesc_3',
  'infoBuyFeatureDesc_4',
];

export const infoTopReelFeatureDesc = [
  'infoTopReelFeatureDesc_1',
  'infoTopReelFeatureDesc_2',
  'infoTopReelFeatureDesc_3',
  'infoTopReelFeatureDesc_4',
  'infoTopReelFeatureDesc_5',
  'infoTopReelFeatureDesc_6',
  'infoTopReelFeatureDesc_7',
];
