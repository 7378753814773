import { EventTypes, GameMode } from '../../global.d';
import { BackGroundSkin } from '../background/background';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import Fujin from './fujin';
import Raijin from './raijin';

class FujinRaijin extends ViewContainer {
  private fujin = new Fujin();

  private raijin = new Raijin();

  private gameMode: GameMode = GameMode.REGULAR;

  private window = { width: 0, height: 0 };

  constructor() {
    super();

    this.addChild(this.fujin);
    this.addChild(this.raijin);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
  }

  private onChangeMode(settings: { mode: GameMode; background?: BackGroundSkin }) {
    this.gameMode = settings.mode;

    this.fujin.gameContainerResize(this.gameMode, this.window.width, this.window.height);
    this.raijin.gameContainerResize(this.gameMode, this.window.width, this.window.height);
  }

  private resize(width: number, height: number): void {
    this.window = { width, height };
  }

  private gameContainerResize(_width: number, _height: number, _x: number, _y: number, _scale: number) {
    this.fujin.gameContainerResize(this.gameMode, this.window.width, this.window.height);
    this.raijin.gameContainerResize(this.gameMode, this.window.width, this.window.height);
  }
}

export default FujinRaijin;
