import * as PIXI from 'pixi.js';
import { Graphics, Sprite } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsFadeOut } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH } from '../background/config';
import { eventManager } from '../config';

import { SCENE_CHANGE_BACKGROUND_COLOR, SceneChangePram, sceneChangeBase, sceneChangeFeature } from './config';

class SceneChange extends PIXI.Container {
  private rect: PIXI.Graphics;

  private SceneChangeSize: { width: number; height: number };

  private SceneChangeScale: number;

  private tomoeSprite: PIXI.Sprite;

  constructor() {
    super();
    this.SceneChangeSize = {
      width: BACKGROUND_SIZE_WIDTH,
      height: BACKGROUND_SIZE_HEIGHT,
    };
    this.SceneChangeScale = 1;

    this.rect = new PIXI.Graphics();
    this.tomoeSprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.tomoe));

    this.addChild(this.rect);
    this.addChild(this.tomoeSprite);
    this.init();

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeScale.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SCENE_CHANGE_DOWN, this.tomoeAnim.bind(this, sceneChangeFeature));
    eventManager.addListener(EventTypes.SCENE_CHANGE_UP, this.tomoeAnim.bind(this, sceneChangeBase));
  }

  private init(): void {
    this.visible = false;

    this.rect.beginFill(SCENE_CHANGE_BACKGROUND_COLOR);
    this.rect.drawRect(0, 0, this.SceneChangeSize.width, this.SceneChangeSize.height);

    this.tomoeSprite.anchor.set(0.5, 0.5);
    this.tomoeSprite.position.set(this.SceneChangeSize.width * 0.5, this.SceneChangeSize.height * 0.5);
  }

  private tomoeAnim = (sceneChangePram: SceneChangePram, changeModeCallBack?: () => void): void => {
    this.visible = true;
    this.tomoeSprite.visible = false;

    const fadeAnimChain = new AnimationChain();
    const tomoeAnimation = new AnimationGroup({});

    const fadeIn = this.getFadeAnim(
      this.rect,
      sceneChangePram.FADE_IN_TIME,
      sceneChangePram.FADE_IN_ALPHA_BEGIN,
      sceneChangePram.FADE_IN_ALPHA_END,
    );
    const fadeOut = this.getFadeAnim(
      this.rect,
      sceneChangePram.FADE_OUT_TIME,
      sceneChangePram.FADE_OUT_ALPHA_BEGIN,
      sceneChangePram.FADE_OUT_ALPHA_END,
    );

    if (changeModeCallBack) {
      fadeOut.addOnStart(() => changeModeCallBack());
    }

    const tomoeRotation = this.getTomoeRotation(
      sceneChangePram.ANIMATION_TIME,
      sceneChangePram.ROTATION_BEGIN,
      sceneChangePram.ROTATION_END,
    );
    const tomoeFade = this.getFadeAnim(
      this.tomoeSprite,
      sceneChangePram.ANIMATION_TIME,
      sceneChangePram.ALPHA_BEGIN,
      sceneChangePram.ALPHA_END,
    );
    const tomoeScaleChange = this.getTomoeScaleAnimation(
      sceneChangePram.ANIMATION_TIME,
      sceneChangePram.SCALE_BEGIN * this.SceneChangeScale,
      sceneChangePram.SCALE_END * this.SceneChangeScale,
    );

    tomoeAnimation.addOnStart(() => {
      AudioApi.play({ type: ISongs.SONG_021_15_Scene_Change });
      this.tomoeSprite.scale.set(sceneChangePram.SCALE_BEGIN);
      this.tomoeSprite.visible = true;
    });

    fadeAnimChain.addOnStart(() => {
      setIsFadeOut(true);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, true);
    });

    fadeAnimChain.addOnComplete(() => {
      this.visible = false;
      setIsFadeOut(false);
      eventManager.emit(EventTypes.SET_IS_FADEOUT, false);
    });

    tomoeAnimation.addAnimation(tomoeRotation);
    tomoeAnimation.addAnimation(tomoeFade);
    tomoeAnimation.addAnimation(tomoeScaleChange);

    fadeAnimChain.appendAnimation(fadeIn);
    fadeAnimChain.appendAnimation(tomoeAnimation);
    fadeAnimChain.appendAnimation(fadeOut);
    fadeAnimChain.start();
  };

  private getTomoeScaleAnimation(duration: number, begin: number, target: number): AnimationGroup {
    const scaleChangeAnim = new AnimationGroup({});

    const scaleX = new Tween({
      object: this.tomoeSprite.scale,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: begin,
      target: target,
    });
    const scaleY = new Tween({
      object: this.tomoeSprite.scale,
      duration,
      property: TweenProperties.Y,
      propertyBeginValue: begin,
      target: target,
    });

    scaleChangeAnim.addAnimation(scaleX);
    scaleChangeAnim.addAnimation(scaleY);
    return scaleChangeAnim;
  }

  private getTomoeRotation(duration: number, begin: number, target: number): Animation {
    const animation = new Tween({
      object: this.tomoeSprite,
      duration,
      property: TweenProperties.ROTATION,
      propertyBeginValue: begin,
      target: target,
    });
    return animation;
  }

  private getFadeAnim(object: Sprite | Graphics, duration: number, begin: number, target: number): Animation {
    const animation = new Tween({
      object: object,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: begin,
      target: target,
    });
    return animation;
  }

  private resize(width: number, height: number): void {
    this.SceneChangeSize.width = width;
    this.SceneChangeSize.height = height;

    this.rect.width = this.SceneChangeSize.width;
    this.rect.height = this.SceneChangeSize.height;

    this.tomoeSprite.position.set(this.SceneChangeSize.width * 0.5, this.SceneChangeSize.height * 0.5);
  }

  private resizeScale(_width: number, _height: number, _x: number, _y: number, scale: number): void {
    this.SceneChangeScale = scale;
  }
}
export default SceneChange;
