import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { destroySpine, isFreeSpinMode } from '../../../utils';
import Animation from '../../animations/animation';
import SpineAnimation from '../../animations/spine';
import Tween from '../../animations/tween';
import ViewContainer from '../../components/container';
import {
  ANTICIPATION_DURATION,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../../config';

class ReelsFrame extends ViewContainer {
  private frame: PIXI.Sprite;

  public anticipationAnimation: Animation | undefined;

  constructor() {
    super();
    this.sortableChildren = true;
    this.frame = this.initFrame();

    this.addChild(this.frame);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.NEARMISS, () => {
      this.anticipationAnimation = this.createAnticipationAnimation();
      this.anticipationAnimation.start();
    });
    eventManager.addListener(EventTypes.NEARMISS_SKIP, () => {
      this.anticipationAnimation?.skip();
    });
  }

  private createAnticipationAnimation(): Animation {
    const dummy = Tween.createDelayAnimation(ANTICIPATION_DURATION);
    const animation = new SpineAnimation({}, PIXI.Loader.shared.resources['effect_frame_nearmiss']!.spineData!);

    dummy.addOnStart(() => {
      animation.getSpine().x = REEL_WIDTH * REELS_AMOUNT - REEL_WIDTH / 2 + 16;
      animation.getSpine().y = SLOTS_CONTAINER_HEIGHT / 2;
      animation.getSpine().state.timeScale = 1;
      animation.getSpine().zIndex = this.frame.zIndex + 1;
      this.addChild(animation.getSpine());
      animation.setAnimation('effect_frame_nearmiss', true);
      animation.addAnimation(0, 'effect_frame_nearmiss_finish', false, 2.3);
      AudioApi.play({ type: ISongs.SONG_021_14_LongSpin, stopPrev: true });
    });
    dummy.addOnComplete(() => {
      AudioApi.stop({ type: ISongs.SONG_021_14_LongSpin });
      this.removeChild(animation.getSpine());
      destroySpine(animation);
    });
    dummy.addOnSkip(() => {
      AudioApi.stop({ type: ISongs.SONG_021_14_LongSpin });
      this.removeChild(animation.getSpine());
      destroySpine(animation);
    });

    return dummy;
  }

  private initFrame(): PIXI.Sprite {
    const sprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelFrameBg));
    this.setPosition(sprite);
    return sprite;
  }

  private onChangeMode(settings: { mode: GameMode }) {
    if (isFreeSpinMode(settings.mode)) {
      this.frame.texture = PIXI.Texture.from(ResourceTypes.reelFrameFg);
    } else {
      this.frame.texture = PIXI.Texture.from(ResourceTypes.reelFrameBg);
    }
    this.setPosition(this.frame);
  }

  private setPosition(frame: PIXI.Sprite): void {
    frame.anchor.set(0.5, 0.5);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
  }
}

export default ReelsFrame;
