export const getRand = (): number => {
  return Math.random() * 100;
};

export const getResultFromTable = (table: number[], rand: number): number => {
  for (let i = 0; i < table.length; i++) {
    if (rand < table[i]!) return i;
  }
  return 0;
};
