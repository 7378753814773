import { Variables } from '../../config';

const GRADIENT_GOLD_AMOUNT_COLOR_CONFIG = {
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: 0,
  stroke: '#333333',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 1,
  miterLimit: 4,
};

const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#333300',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 1,
  dropShadowBlur: 0,
  dropShadowDistance: 1,
};
export const titleTextStyle = {
  fontSize: 120,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 6,
};
export const descriptionsTextStyle = {
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#FFFFFF',
  fillGradientType: 0,
  stroke: '#EEEEEE',
  strokeThickness: 2,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 1,
  dropShadowBlur: 0,
  dropShadowDistance: 1,
};
export const btnTextStyle = {
  fontSize: 60,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#aa3333',
  fillGradientType: 0,
  stroke: '#aa3333',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
};
export const totalWinTitleStyles = {
  fontSize: 140,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_AMOUNT_COLOR_CONFIG,
  strokeThickness: 7,
};
export const totalWinAmountTextStyles = {
  fontSize: 160,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_AMOUNT_COLOR_CONFIG,
  strokeThickness: 8,
};
export const FreeSpinsStyles = {
  fontSize: 80,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 2,
};
export const andTextStyle = {
  fontSize: 50,
  fontFamily: Variables.FONT_FAMILY,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 2,
};
export const bonusStreakTextStyle = {
  fontSize: 110,
  fontFamily: Variables.FONT_FAMILY,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#ffcc38'],
  //fill: ['#b3ffff', '#0078ff', '#007dd4', '#20edff', '#2d28ff', '#0600ff', '#5b53ff', '#00065b', '#ffffff'],
  //fillGradientStops: [0.2, 0.38, 0.5, 0.53, 0.55, 0.59, 0.66, 0.8, 1.0],
  //fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fontWeight: 'bolder',
  lineJoin: 'round',
};
