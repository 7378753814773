import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, UserBonus } from '../../global.d';
import { setCurrentBonus, setNextResult } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode } from '../../utils/helper';
import { updateTextScale } from '../../utils/utils';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { GAME_CONTAINER_HEIGHT, eventManager } from '../config';

import {
  GRADIENT_STOCK_OVER_TEXT_COLOR_CONFIG,
  STOCK_ANIMATION_FADE_DURATION,
  STOCK_ANIMATION_MOVE_DURATION,
  STOCK_CURRENT_SCALE,
  STOCK_DEFAULT_SCALE,
  STOCK_MAX_DISPLAYED_NUM,
  STOCK_OVER_TEXT_HEIGHT,
  STOCK_OVER_TEXT_WIDTH,
  StockObjectsPriorities,
  stockXPositions,
} from './config';
import Stock from './stock';

class StockContainer extends ViewContainer {
  private stockOverText: PIXI.Text;

  private stocks: Stock[] = [];

  private nextPayouts: number[] = [];

  private animation: AnimationGroup | undefined;

  private addEffect = new Spine(PIXI.Loader.shared.resources['feature_stock_light']!.spineData!);

  private cursorEffect = new Spine(PIXI.Loader.shared.resources['feature_stock_arrival']!.spineData!);

  private isAfterGameBroken = false;

  constructor() {
    super();
    this.x = 45;
    this.y = GAME_CONTAINER_HEIGHT + 60;
    this.sortableChildren = true;

    const backStage = this.initBackStage();
    this.addChild(backStage);

    this.createBlankStocks();
    for (let i = 0; i < STOCK_MAX_DISPLAYED_NUM; i++) {
      this.addChild(this.stocks[i]!);
    }

    const frontStage = this.initFrontStage();
    this.addChild(frontStage);

    this.stockOverText = this.initStockOverText();
    this.addChild(this.stockOverText);

    this.initAddEffect();
    this.addChild(this.addEffect);

    this.cursorEffect.visible = false;
    this.cursorEffect.zIndex = StockObjectsPriorities['CURRENT_STOCK_EFFECT'];
    this.addChild(this.cursorEffect);

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.animation?.skip();
    });
    eventManager.addListener(EventTypes.MOVE_FREE_SPINS_STOCK, this.moveStart.bind(this));
    eventManager.addListener(EventTypes.ADD_FREE_SPINS, this.onAddFreeSpins.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) => {
      this.onChangeMode(settings.mode);
      if (settings.mode === GameMode.FREE_SPINS) {
        this.onInitStocksDisplay(setCurrentBonus(), setNextResult()!.bet.data.features.gameRoundStore.nextPayouts);
      }
    });
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, (settings: { mode: GameMode }) => {
      this.onChangeMode(settings.mode);

      if (settings.mode === GameMode.FREE_SPINS) {
        const bonus = setCurrentBonus();
        const roundsRemaining = bonus.currentRound === 0 ? bonus.rounds : bonus.rounds - bonus.currentRound + 1;
        this.nextPayouts = Array<number>(STOCK_MAX_DISPLAYED_NUM);
        this.updateStocks(roundsRemaining);
        this.updateDisplayOverText(roundsRemaining);
        this.isAfterGameBroken = true;
      }
    });

    this.visible = false;
    //this.showDebug();
  }

  private showDebug() {
    this.visible = true;
    this.nextPayouts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.updateStocks(STOCK_MAX_DISPLAYED_NUM);
    this.updateDisplayOverText(20);

    this.stocks.forEach((stock) => {
      stock.setIdleAnimation();
    });
  }

  private createBlankStocks() {
    for (let i = 0; i < STOCK_MAX_DISPLAYED_NUM; i++) {
      this.stocks![i] = new Stock('Blank', i, STOCK_DEFAULT_SCALE);
    }
    this.stocks[0]!.scale.set(STOCK_CURRENT_SCALE);
  }

  private initBackStage() {
    const backStage = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.stockStageBack));
    backStage.x = -60;
    backStage.anchor.y = 0.5;
    backStage.zIndex = StockObjectsPriorities['BACK_STAGE'];

    return backStage;
  }

  private initFrontStage() {
    const frontStage = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.stockStage));
    frontStage.x = -60;
    frontStage.anchor.y = 0.5;
    frontStage.zIndex = StockObjectsPriorities['FRONT_STAGE'];
    return frontStage;
  }

  private initStockOverText() {
    const text = new PIXI.Text('10', GRADIENT_STOCK_OVER_TEXT_COLOR_CONFIG);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.x = stockXPositions[STOCK_MAX_DISPLAYED_NUM]!;
    text.zIndex = StockObjectsPriorities['OVER_COUNT_TEXT'];

    return text;
  }

  private initAddEffect() {
    this.addEffect.y = -350;
    this.addEffect.x = 450;
    this.addEffect.zIndex = StockObjectsPriorities['ADD_EFFECT']!;
  }

  private deleteStock() {
    this.removeChild(this.stocks[0]!);
    this.stocks.shift();
  }

  private createStock() {
    const addIndex = STOCK_MAX_DISPLAYED_NUM - 1;
    const newStock = new Stock('Blank', addIndex, STOCK_DEFAULT_SCALE);
    this.stocks.push(newStock);
    this.addChild(this.stocks[addIndex]!);
  }

  private moveStart(): void {
    const bonus = setCurrentBonus();
    const roundsRemaining = bonus.rounds - bonus.currentRound;

    const animationGroup = new AnimationGroup();
    const deleteFadeOutAnimation = this.stocks[0]!.getFadeAnimation(STOCK_ANIMATION_FADE_DURATION, 1, 0);
    const currentScaleAnimation = this.stocks[1]!.getScaleAnimation(
      STOCK_ANIMATION_MOVE_DURATION,
      STOCK_DEFAULT_SCALE,
      STOCK_CURRENT_SCALE,
    );
    const moveAnimation = this.getStocksMoveAnimation(STOCK_ANIMATION_MOVE_DURATION);

    currentScaleAnimation.addOnStart(() => {
      this.stocks[1]!.scale.set(1);
    });

    animationGroup.addOnSkip(() => {
      deleteFadeOutAnimation.skip();
      moveAnimation.skip();
    });

    animationGroup.addAnimation(deleteFadeOutAnimation);
    animationGroup.addAnimation(currentScaleAnimation);
    animationGroup.addAnimation(moveAnimation);

    animationGroup.addOnComplete(() => {
      this.deleteStock();
      if (setNextResult()) {
        const nextPayouts = [...setNextResult()!.bet.data.features.gameRoundStore.nextPayouts];
        this.nextPayouts = nextPayouts;

        // restore after GameBroken winStage
        if (this.isAfterGameBroken) {
          this.stocks.forEach((stock, index) => {
            stock.setWinStage(this.nextPayouts[index]);
          });
          this.isAfterGameBroken = false;
        }
      }
      this.createStock();
      this.updateStocks(roundsRemaining);
      this.updateDisplayOverText(roundsRemaining);

      this.stocks.forEach((stock, index) => {
        stock.changeStockColor(
          stock.lotColorRankChange(stock.getColorState(), stock.getWinStage()),
          index,
          index === 0 ? STOCK_CURRENT_SCALE : 1,
        );
      });

      if (roundsRemaining === 0) {
        this.cursorEffect.visible = false;
      }
    });

    this.animation = animationGroup;

    this.animation.start();
  }

  private getStocksMoveAnimation(duration: number): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 1; i < STOCK_MAX_DISPLAYED_NUM; i++) {
      animationGroup.addAnimation(this.stocks[i]!.getMoveAnimation(duration, this.stocks[i - 1]!.x));
    }
    return animationGroup;
  }

  private updateStocks(remainRounds: number, isAddSpin?: boolean): void {
    this.stocks.forEach((stock, index) => {
      if (index < remainRounds) {
        if (stock.getColorState() === 'Blank') {
          stock.setWinStage(this.nextPayouts[index]);
          stock.setColorState('White');
          if (isAddSpin) {
            this.startAddStockEffect(stock.position);
            stock.setLoopAndIdleAnimation();
          } else {
            stock.setIdleAnimation();
          }
        }
      }
    });

    if (!isAddSpin) {
      this.stocks[0]!.setCurrentAnimation();
    }
  }

  private startAddStockEffect(position: { x: number; y: number }) {
    const addStockEffect = new Spine(PIXI.Loader.shared.resources['feature_stock_arrival']!.spineData!);
    addStockEffect.visible = true;
    addStockEffect.position = position;
    addStockEffect.zIndex = StockObjectsPriorities['ADD_STOCK_EFFECT']!;
    const duration = (addStockEffect.skeleton.data.findAnimation('feature_stock_arrival')?.duration ?? 0) * 1000;
    const animation = Tween.createDelayAnimation(duration);

    animation.addOnStart(() => {
      this.addChild(addStockEffect);
      addStockEffect.state.setAnimation(0, 'feature_stock_arrival', false);
    });

    animation.addOnComplete(() => {
      addStockEffect.removeChild(addStockEffect);
    });
    animation.start();
  }

  private updateDisplayOverText(remainRounds: number, isAddSpin = false) {
    if (remainRounds > STOCK_MAX_DISPLAYED_NUM) {
      this.stockOverText.visible = true;
      this.stockOverText.text = `+${remainRounds - STOCK_MAX_DISPLAYED_NUM}`;
      if (isAddSpin) {
        this.startAddStockEffect({ x: this.stockOverText.x + 5, y: this.stockOverText.y });
      }
    } else {
      this.stockOverText.visible = false;
    }

    updateTextScale(this.stockOverText, STOCK_OVER_TEXT_WIDTH, STOCK_OVER_TEXT_HEIGHT);
  }

  private onInitStocksDisplay(bonus: UserBonus, nextPayouts: number[]) {
    if (!nextPayouts) return;

    this.nextPayouts = [...nextPayouts];

    this.updateStocks(STOCK_MAX_DISPLAYED_NUM);
    this.updateDisplayOverText(bonus.rounds);

    this.cursorEffect.visible = true;
    this.cursorEffect.state.setAnimation(0, 'feature_stock_arrival_current', true);
  }

  private onAddFreeSpins(bonus: UserBonus) {
    const remainRounds = bonus.rounds - bonus.currentRound;

    const addAnimation = Tween.createDelayAnimation(1300);
    addAnimation.addOnStart(() => {
      this.addEffect.state.setAnimation(0, 'feature_stock_light', false);
      AudioApi.play({ type: ISongs.SONG_021_15_Scene_Change });
    });
    addAnimation.addOnComplete(() => {
      this.updateStocks(remainRounds + 1, true);
      this.updateDisplayOverText(remainRounds + 1, true);
      AudioApi.play({ type: ISongs.SONG_021_21_FS_add });
    });

    addAnimation.start();
  }

  private onChangeMode(mode: GameMode) {
    this.visible = isFreeSpinMode(mode);
  }
}

export default StockContainer;
