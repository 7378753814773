import _ from 'lodash';

import { TAudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  SONG_021_01_BaseGameBGM_Base = '021_01_BaseGameBGM_Base',
  SONG_021_02_BaseGameBGM_Melo = '021_02_BaseGameBGM_Melo',
  SONG_021_03_FreeSpinBGM = '021_03_FreeSpinBGM',
  SONG_021_04_TotalWinBanner = '021_04_TotalWinBanner',
  SONG_021_05_BigWin_Intro = '021_05_BigWin_Intro',
  SONG_021_06_BigWin_Loop = '021_06_BigWin_Loop',
  SONG_021_07_BigWin_END = '021_07_BigWin_END',
  SONG_021_11_Scatter_01 = '021_11_Scatter_01',
  SONG_021_12_Scatter_02 = '021_12_Scatter_02',
  SONG_021_13_Scatter_03 = '021_13_Scatter_03',
  SONG_021_14_LongSpin = '021_14_LongSpin',
  SONG_021_15_Scene_Change = '021_15_Scene_Change',
  SONG_021_16_Thunder = '021_16_Thunder',
  SONG_021_17_Wind = '021_17_Wind',
  SONG_021_18_6th_Spin = '021_18_6th_Spin',
  SONG_021_19_6th_Stop = '021_19_6th_Stop',
  SONG_021_20_Stock_Upgrade = '021_20_Stock_Upgrade',
  SONG_021_21_FS_add = '021_21_FS_add',
  SONG_021_22_Yocho_Phoenix = '021_22_Yocho_Phoenix',
  SONG_021_23_Thunder_FS = '021_23_Thunder_FS',
  SONG_FeatureTrigger = 'FeatureTrigger',
  SONG_SFX_BuyFeature = 'SFX_BuyFeature',
  SONG_SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SONG_SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SONG_SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SONG_SFX_UI_BetChange = 'SFX_UI_BetChange',
  SONG_SFX_UI_Close = 'SFX_UI_Close',
  SONG_SFX_UI_General = 'SFX_UI_General',
  SONG_SFX_UI_Hover = 'SFX_UI_Hover',
  SONG_SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SONG_SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SONG_SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SONG_SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SONG_SFX_Win_Big = 'SFX_Win_Big',
  SONG_SFX_Win_Epic = 'SFX_Win_Epic',
  SONG_SFX_Win_Great = 'SFX_Win_Great',
  SONG_SFX_Win_Mega = 'SFX_Win_Mega',
}

export const audioSprite: TAudioSprite = {
  [ISongs.SONG_021_01_BaseGameBGM_Base]: [0, 74666.66666666667, true],
  [ISongs.SONG_021_02_BaseGameBGM_Melo]: [76000, 74666.66666666669, true],
  [ISongs.SONG_021_03_FreeSpinBGM]: [152000, 65626.66666666667, true],
  [ISongs.SONG_021_04_TotalWinBanner]: [219000, 4111.111111111114],
  [ISongs.SONG_021_05_BigWin_Intro]: [225000, 2000],
  [ISongs.SONG_021_06_BigWin_Loop]: [228000, 64000, true],
  [ISongs.SONG_021_07_BigWin_END]: [293000, 4635.464852607697],
  [ISongs.SONG_021_11_Scatter_01]: [299000, 708.0045351473814],
  [ISongs.SONG_021_12_Scatter_02]: [301000, 700.9750566893445],
  [ISongs.SONG_021_13_Scatter_03]: [303000, 713.3333333333098],
  [ISongs.SONG_021_14_LongSpin]: [305000, 3676.8934240362796],
  [ISongs.SONG_021_15_Scene_Change]: [310000, 1502.0181405895414],
  [ISongs.SONG_021_16_Thunder]: [313000, 1477.006802721064],
  [ISongs.SONG_021_17_Wind]: [316000, 976.3492063491981],
  [ISongs.SONG_021_18_6th_Spin]: [318000, 3000],
  [ISongs.SONG_021_19_6th_Stop]: [322000, 267.0294784580278],
  [ISongs.SONG_021_20_Stock_Upgrade]: [324000, 1213.3333333333098],
  [ISongs.SONG_021_21_FS_add]: [327000, 1417.4603174603249],
  [ISongs.SONG_021_22_Yocho_Phoenix]: [330000, 4033.33333333336],
  [ISongs.SONG_021_23_Thunder_FS]: [336000, 3127.437641723361],
  [ISongs.SONG_FeatureTrigger]: [341000, 879.841269841279],
  [ISongs.SONG_SFX_BuyFeature]: [343000, 666.6666666666856],
  [ISongs.SONG_SFX_SM_CountUp_End]: [345000, 933.4693877551103],
  [ISongs.SONG_SFX_SM_CountUp_Loop]: [347000, 399.7505668934309, true],
  [ISongs.SONG_SFX_UI_AutoSpin]: [349000, 320.47619047619946],
  [ISongs.SONG_SFX_UI_BetChange]: [351000, 32.08616780045759],
  [ISongs.SONG_SFX_UI_Close]: [353000, 200.68027210885475],
  [ISongs.SONG_SFX_UI_General]: [355000, 88.34467120180989],
  [ISongs.SONG_SFX_UI_Hover]: [357000, 151.76870748297233],
  [ISongs.SONG_SFX_UI_MaxBet]: [359000, 122.92517006801518],
  [ISongs.SONG_SFX_UI_MenuOpen]: [361000, 277.1428571428487],
  [ISongs.SONG_SFX_UI_SpinStart]: [363000, 553.3333333333417],
  [ISongs.SONG_SFX_UI_SpinStop]: [365000, 633.3333333333258],
  [ISongs.SONG_SFX_Win_Big]: [367000, 1622.3356009070358],
  [ISongs.SONG_SFX_Win_Epic]: [370000, 1862.2675736961583],
  [ISongs.SONG_SFX_Win_Great]: [373000, 2118.390022675726],
  [ISongs.SONG_SFX_Win_Mega]: [377000, 1696.1678004535088],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.SONG_021_01_BaseGameBGM_Base]: 0.3,
  [ISongs.SONG_021_02_BaseGameBGM_Melo]: 0.3,
  [ISongs.SONG_021_03_FreeSpinBGM]: 0.3,
  [ISongs.SONG_021_04_TotalWinBanner]: 0.3,
  [ISongs.SONG_021_05_BigWin_Intro]: 0.3,
  [ISongs.SONG_021_06_BigWin_Loop]: 0.3,
  [ISongs.SONG_021_07_BigWin_END]: 0.3,
  [ISongs.SONG_021_11_Scatter_01]: 0.5,
  [ISongs.SONG_021_12_Scatter_02]: 0.5,
  [ISongs.SONG_021_13_Scatter_03]: 0.5,
  [ISongs.SONG_021_14_LongSpin]: 0.4,
  [ISongs.SONG_021_15_Scene_Change]: 0.3,
  [ISongs.SONG_021_16_Thunder]: 0.2,
  [ISongs.SONG_021_17_Wind]: 0.2,
  [ISongs.SONG_021_18_6th_Spin]: 0.2,
  [ISongs.SONG_021_19_6th_Stop]: 0.2,
  [ISongs.SONG_021_20_Stock_Upgrade]: 0.5,
  [ISongs.SONG_021_21_FS_add]: 0.5,
  [ISongs.SONG_021_22_Yocho_Phoenix]: 0.3,
  [ISongs.SONG_021_23_Thunder_FS]: 0.3,
  [ISongs.SONG_FeatureTrigger]: 0.3,
  [ISongs.SONG_SFX_BuyFeature]: 0.3,
  [ISongs.SONG_SFX_SM_CountUp_End]: 0.3,
  [ISongs.SONG_SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SONG_SFX_UI_AutoSpin]: 0.3,
  [ISongs.SONG_SFX_UI_BetChange]: 0.3,
  [ISongs.SONG_SFX_UI_Close]: 0.3,
  [ISongs.SONG_SFX_UI_General]: 0.3,
  [ISongs.SONG_SFX_UI_Hover]: 0.3,
  [ISongs.SONG_SFX_UI_MaxBet]: 0.3,
  [ISongs.SONG_SFX_UI_MenuOpen]: 0.3,
  [ISongs.SONG_SFX_UI_SpinStart]: 0.3,
  [ISongs.SONG_SFX_UI_SpinStop]: 0.4,
  [ISongs.SONG_SFX_Win_Big]: 0.3,
  [ISongs.SONG_SFX_Win_Epic]: 0.6,
  [ISongs.SONG_SFX_Win_Great]: 0.5,
  [ISongs.SONG_SFX_Win_Mega]: 0.4,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
