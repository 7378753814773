import { MessageFreeSpinsBannerProps } from '../../global';
import i18n from '../../i18next';
import { updateTextScale } from '../../utils';
import { GAME_CONTAINER_HEIGHT } from '../config';
import AutoResizeText from '../text/autoResizeText';

import { BaseMessageBanner } from './baseMessageBanner';
import { btnTextStyle, descriptionsTextStyle, titleTextStyle } from './textStyles';

export class MessageFreeSpinsBanner extends BaseMessageBanner {
  protected override props: MessageFreeSpinsBannerProps;

  private title?: AutoResizeText;

  private description?: AutoResizeText;

  private descriptions: AutoResizeText[] = [];

  private btn?: AutoResizeText;

  constructor(props: MessageFreeSpinsBannerProps) {
    super(props);
    this.props = props;
    this.y = -GAME_CONTAINER_HEIGHT / 4;
  }

  public override init(): MessageFreeSpinsBanner {
    super.init();

    this.title = this.initTitle(this.props.title);
    this.description = this.initDescription(this.props.description);
    this.descriptions = this.initDescriptions();
    this.btn = this.initBtn(this.props.btnText);

    this.addChild(this.title, this.description, this.btn);
    this.addChild(...this.descriptions);
    return this;
  }

  private initTitle(titleText: string): AutoResizeText {
    const title = new AutoResizeText(titleText, titleTextStyle);
    title.anchor.set(0.5, 0.5);
    title.y = 225;
    updateTextScale(title, this.banner!.width - 250, 250);

    return title;
  }

  private initDescription(descriptionText: string): AutoResizeText {
    const description = new AutoResizeText(descriptionText, titleTextStyle);
    description.anchor.set(0.5, 0.5);
    description.y = 400;
    updateTextScale(description, this.banner!.width - 250, 250);

    return description;
  }

  private initDescriptions(): AutoResizeText[] {
    const descriptions = [];
    const description1 = new AutoResizeText(i18n.t<string>('freeSpinsDesc_1'), descriptionsTextStyle);
    const description2 = new AutoResizeText(i18n.t<string>('freeSpinsDesc_2'), descriptionsTextStyle);
    description1.anchor.set(0.5, 0.5);
    description2.anchor.set(0.5, 0.5);
    description1.y = 530;
    description2.y = description1.y + description1.height;

    updateTextScale(description1, this.banner!.width - 250, 250);
    updateTextScale(description2, this.banner!.width - 250, 250);

    descriptions.push(description1, description2);

    return descriptions;
  }

  private initBtn(btnText: string): AutoResizeText {
    const btn = new AutoResizeText(btnText, btnTextStyle);
    btn.anchor.set(0.5, 0);
    btn.y = 680;
    updateTextScale(btn, this.banner!.width - 250, 250);

    return btn;
  }
}
