import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { isFreeSpinMode } from '../../../utils';
import ViewContainer from '../../components/container';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private background: PIXI.Sprite;

  constructor() {
    super();

    this.background = this.initReelBackground();
    this.addChild(this.background);

    eventManager.on(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  private onChangeMode(settings: { mode: GameMode }) {
    if (isFreeSpinMode(settings.mode)) {
      this.background.texture = PIXI.Texture.from(ResourceTypes.reelsBackFg);
    } else {
      this.background.texture = PIXI.Texture.from(ResourceTypes.reelsBackBg);
    }
  }

  private initReelBackground(): PIXI.Sprite {
    const texture = PIXI.Texture.from(ResourceTypes.reelsBackBg);
    const background = new PIXI.Sprite(texture);
    background.anchor.set(0.5, 0.5);
    background.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    return background;
  }
}

export default ReelsBackgroundContainer;
