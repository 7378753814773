import { ISongs } from '../../config';

export const baseGameScatterStopSoundList = [
  {
    sound: ISongs.SONG_021_11_Scatter_01,
    reelId: 0,
  },
  {
    sound: ISongs.SONG_021_12_Scatter_02,
    reelId: 2,
  },
  {
    sound: ISongs.SONG_021_13_Scatter_03,
    reelId: 4,
  },
];

export const freeSpinsScatterStopSoundList = [
  ISongs.SONG_021_11_Scatter_01,
  ISongs.SONG_021_12_Scatter_02,
  ISongs.SONG_021_13_Scatter_03,
];
