export const SCENE_CHANGE_BACKGROUND_COLOR = 0x000000;
export const SCENE_CHANGE_FADE_TIME = 1000;

export interface SceneChangePram {
  FADE_IN_TIME: number;
  FADE_IN_ALPHA_BEGIN: number;
  FADE_IN_ALPHA_END: number;
  FADE_OUT_TIME: number;
  FADE_OUT_ALPHA_BEGIN: number;
  FADE_OUT_ALPHA_END: number;
  ANIMATION_TIME: number;
  ALPHA_BEGIN: number;
  ALPHA_END: number;
  ROTATION_BEGIN: number;
  ROTATION_END: number;
  SCALE_BEGIN: number;
  SCALE_END: number;
}

export const sceneChangeFeature: SceneChangePram = {
  FADE_IN_TIME: 1000,
  FADE_IN_ALPHA_BEGIN: 0,
  FADE_IN_ALPHA_END: 1,
  FADE_OUT_TIME: 1000,
  FADE_OUT_ALPHA_BEGIN: 1,
  FADE_OUT_ALPHA_END: 0,
  ANIMATION_TIME: 1000,
  ALPHA_BEGIN: 1,
  ALPHA_END: 0,
  ROTATION_BEGIN: 0,
  ROTATION_END: 4.1,
  SCALE_BEGIN: 0,
  SCALE_END: 5,
};

export const sceneChangeBase: SceneChangePram = {
  FADE_IN_TIME: 1000,
  FADE_IN_ALPHA_BEGIN: 0,
  FADE_IN_ALPHA_END: 1,
  FADE_OUT_TIME: 1000,
  FADE_OUT_ALPHA_BEGIN: 1,
  FADE_OUT_ALPHA_END: 0,
  ANIMATION_TIME: 1000,
  ALPHA_BEGIN: 1,
  ALPHA_END: 0,
  ROTATION_BEGIN: 0,
  ROTATION_END: -4.1,
  SCALE_BEGIN: 5,
  SCALE_END: 0,
};
