type CharacterLayoutParameter = {
  baseGame: {
    landScape: {
      x: number;
      y: number;
      scale: number;
    };
    portrait: {
      x: number;
      y: number;
      scale: number;
    };
  };
  freeSpins: {
    landScape: {
      x: number;
      y: number;
      scale: number;
    };
    portrait: {
      x: number;
      y: number;
      scale: number;
    };
  };
};

export const raijinLayout: CharacterLayoutParameter = {
  baseGame: {
    landScape: {
      x: -330,
      y: 120,
      scale: 1,
    },
    portrait: {
      x: 130,
      y: -390,
      scale: 1 * 0.8,
    },
  },
  freeSpins: {
    landScape: {
      x: -330,
      y: 240,
      scale: 1 * 1.2,
    },
    portrait: {
      x: 130,
      y: -390,
      scale: 1 * 0.8,
    },
  },
};

export const raijinAttackEffectLayout: CharacterLayoutParameter = {
  baseGame: {
    landScape: {
      x: -200,
      y: 120,
      scale: 1,
    },
    portrait: {
      x: 200,
      y: -290,
      scale: 1 * 0.8,
    },
  },
  freeSpins: {
    landScape: {
      x: -200,
      y: 270,
      scale: 1 * 1.2,
    },
    portrait: {
      x: 200,
      y: -290,
      scale: 1 * 0.8,
    },
  },
};

export const fujinLayout: CharacterLayoutParameter = {
  baseGame: {
    landScape: {
      x: 1150,
      y: 470,
      scale: 0.9,
    },
    portrait: {
      x: 900,
      y: -350,
      scale: 0.9 * 0.8,
    },
  },
  freeSpins: {
    landScape: {
      x: 1200,
      y: 190,
      scale: 1 * 1.2,
    },
    portrait: {
      x: 900,
      y: -350,
      scale: 1 * 0.8,
    },
  },
};

export const fujinAttackEffectLayout: CharacterLayoutParameter = {
  baseGame: {
    landScape: {
      x: 1220,
      y: 430,
      scale: 0.9,
    },
    portrait: {
      x: 900,
      y: -350,
      scale: 0.9 * 0.8,
    },
  },
  freeSpins: {
    landScape: {
      x: 1210,
      y: 150,
      scale: 1 * 1.2,
    },
    portrait: {
      x: 900,
      y: -350,
      scale: 1 * 0.8,
    },
  },
};
