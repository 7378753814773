import { upgradeConfig } from '@pixi/particle-emitter';

import { ResourceTypes } from '../../resources.d';

export const glitterConfig = upgradeConfig(
  {
    alpha: {
      start: 0.3,
      end: 0.01,
    },
    scale: {
      start: 1.0,
      end: 0.8,
      minimumScaleMultiplier: 1,
    },
    color: {
      start: '#aaaaaa',
      end: '#000000',
    },
    speed: {
      start: 3000,
      end: 3000,
      minimumSpeedMultiplier: 1,
    },
    acceleration: {
      x: 0,
      y: 0,
    },
    maxSpeed: 0,
    startRotation: {
      min: 65,
      max: 65,
    },
    noRotation: false,
    rotationSpeed: {
      min: 0,
      max: 0,
    },
    lifetime: {
      min: 0.81,
      max: 0.81,
    },
    blendMode: 'screen',
    frequency: 0.002,
    emitterLifetime: -1,
    maxParticles: 1000,
    pos: {
      x: 0,
      y: 0,
    },
    addAtBack: false,
    spawnType: 'rect',
    spawnRect: {
      x: -1280,
      y: -540,
      w: 2280,
      h: 500,
    },
  },
  [ResourceTypes.particle, ResourceTypes.sparks],
);
