import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      date: 'date',
      bet: 'bet',
      win: 'win',
      gameId: 'Game ID',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.symbolWl,
      combos: [
        { pattern: 'x5', multiplier: 2000 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 100 },
      ],
    },
    {
      slug: ResourceTypes.symbolSc,
      combos: [{ pattern: 'x3', multiplier: 1 }],
    },
    {
      slug: ResourceTypes.symbolA,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 50 },
      ],
    },
    {
      slug: ResourceTypes.symbolB,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 50 },
      ],
    },
    {
      slug: ResourceTypes.symbolC,
      combos: [
        { pattern: 'x5', multiplier: 120 },
        { pattern: 'x4', multiplier: 24 },
        { pattern: 'x3', multiplier: 12 },
      ],
    },
    {
      slug: ResourceTypes.symbolD,
      combos: [
        { pattern: 'x5', multiplier: 120 },
        { pattern: 'x4', multiplier: 24 },
        { pattern: 'x3', multiplier: 12 },
      ],
    },
    {
      slug: ResourceTypes.symbolE,
      combos: [
        { pattern: 'x5', multiplier: 120 },
        { pattern: 'x4', multiplier: 24 },
        { pattern: 'x3', multiplier: 12 },
      ],
    },
    {
      slug: ResourceTypes.symbolF,
      combos: [
        { pattern: 'x5', multiplier: 20 },
        { pattern: 'x4', multiplier: 4 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolG,
      combos: [
        { pattern: 'x5', multiplier: 20 },
        { pattern: 'x4', multiplier: 4 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.symbolH,
      combos: [
        { pattern: 'x5', multiplier: 20 },
        { pattern: 'x4', multiplier: 4 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
  ],
};

export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  WL = 'WL',
  SC1 = 'SC1',
  SC2 = 'SC2',
}

export enum SlotIdPriority {
  A = 80,
  B = 100,
  C = 120,
  D = 0,
  E = 20,
  F = 40,
  G = 60,
  H = 60,
  WL = 140,
  SC1 = 160,
  SC2 = 160,
}

export enum Colors {
  WHITE_COLOR = 0xffffff,
  GAME_COLOR = 0xffcc48,
}

export enum Variables {
  FONT_FAMILY = 'NotoSans-SemiCondensedBold',
}
