import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setSlotConfig } from '../../gql';
import ViewContainer from '../components/container';
import {
  REELS_AMOUNT,
  REEL_HEIGHT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
} from '../config';
import { IWinLine } from '../d';

export class WinSymbolHightLightsContainer extends ViewContainer {
  private defaultLinePayLights: Spine[] = [];

  private rightLinePayLights: Spine[] = [];

  private startRightLineId = 0;

  constructor() {
    super();

    const winLineLength = setSlotConfig().winLines.length;
    this.startRightLineId = winLineLength / 2;

    const defaultLinePayLights = this.createSpines();
    defaultLinePayLights.forEach((spine) => {
      this.defaultLinePayLights.push(spine);
      this.addChild(spine);
    });

    const rightLinePayLights = this.createSpines();
    rightLinePayLights.forEach((spine) => {
      this.rightLinePayLights.push(spine);
      this.addChild(spine);
    });

    eventManager.addListener(EventTypes.SHOW_WIN_LINES, this.onShowWinLines.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_LINES, this.onHideWinLines.bind(this));
  }

  private createSpines() {
    const spines = [];
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const id = i * REELS_AMOUNT + j;
        const spine = new Spine(PIXI.Loader.shared.resources['symbol_frame_payline_light']!.spineData!);
        spine.x = REEL_WIDTH * (id % REELS_AMOUNT) + SLOT_WIDTH / 2;
        spine.y = REEL_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
        spines.push(spine);
      }
    }
    return spines;
  }

  private onShowWinLines(winLines: IWinLine[]) {
    this.defaultLinePayLights.forEach((spine) => (spine.visible = false));
    this.rightLinePayLights.forEach((spine) => (spine.visible = false));

    winLines.forEach((line) => {
      line.winPositions.forEach((position) => {
        if (line.lineId !== null) {
          if (line.lineId < this.startRightLineId) {
            const defaultLinePayLight = this.defaultLinePayLights[position]!;
            defaultLinePayLight.visible = true;
            defaultLinePayLight.state.setAnimation(0, 'payline_light_left', false);
          } else {
            const rightLinePayLight = this.rightLinePayLights[position]!;
            rightLinePayLight.visible = true;
            rightLinePayLight.state.setAnimation(0, 'payline_light_right', false);
          }
        }
      });
    });
  }

  private onHideWinLines() {
    this.defaultLinePayLights.forEach((spine) => (spine.visible = false));
    this.rightLinePayLights.forEach((spine) => (spine.visible = false));
  }
}
