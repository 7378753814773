import { GameMode, SixReelMultiplierType, SixReelSlotId } from '../../../global.d';

export const SIXTH_REEL_FRAME_HEIGHT = 227;

export const SIXTH_RAIJIN_ATTACK_ANIMATION_FRAME = 300;
export const SIXTH_FUJIN_ATTACK_ANIMATION_FRAME = 990;
export const SIXTH_REEL_SPIN_SPEED = 20 / 60;

export type SixReelPositionType = 0 | 1 | 2 | 3 | 4 | 5;

export const MAPPED_SIX_REEL_POSITIONS: Record<SixReelSlotId, SixReelPositionType> = {
  [SixReelSlotId.X1]: 0,
  [SixReelSlotId.X2]: 1,
  [SixReelSlotId.X3]: 2,
  [SixReelSlotId.X4]: 3,
  [SixReelSlotId.X5]: 4,
  [SixReelSlotId.SP]: 5,
};

export const MAPPED_SIX_REEL_MULTIPLIER_POSITIONS: Record<SixReelMultiplierType, SixReelPositionType> = {
  1: MAPPED_SIX_REEL_POSITIONS[SixReelSlotId.X1],
  2: MAPPED_SIX_REEL_POSITIONS[SixReelSlotId.X2],
  3: MAPPED_SIX_REEL_POSITIONS[SixReelSlotId.X3],
  5: MAPPED_SIX_REEL_POSITIONS[SixReelSlotId.X4],
  8: MAPPED_SIX_REEL_POSITIONS[SixReelSlotId.X5],
};

export const SIXTH_REVERSE_SPIN_LOTTERY_DENOMINATOR = 100;
export const SIXTH_BASE_GAME_REVERSE_SPIN_LOTTERY_VALUE = 20;
export const SIXTH_FREE_SPINS_REVERSE_SPIN_LOTTERY_VALUE = 40;

export const sixReverseSpinLotteryValues: Record<GameMode, number> = {
  [GameMode.REGULAR]: SIXTH_BASE_GAME_REVERSE_SPIN_LOTTERY_VALUE,
  [GameMode.BUY_FEATURE]: SIXTH_BASE_GAME_REVERSE_SPIN_LOTTERY_VALUE,
  [GameMode.FREE_SPINS]: SIXTH_FREE_SPINS_REVERSE_SPIN_LOTTERY_VALUE,
};

export const SixReelIdleAnimationNames: Record<SixReelPositionType, string> = {
  0: 'sixth_reel_x1_idle',
  1: 'sixth_reel_x2_idle',
  2: 'sixth_reel_x3_idle',
  3: 'sixth_reel_x5_idle',
  4: 'sixth_reel_x8_idle',
  5: 'sixth_reel_bonus_idle',
};

type SixReelSpinAnimationType = { last: string; first: string };

export const SixReelStopAnimationNames: Record<SixReelPositionType, SixReelSpinAnimationType> = {
  0: { last: 'sixth_reel_x1_stop', first: '' },
  1: { last: 'sixth_reel_x2_stop', first: 'sixth_reel_x1_stop' },
  2: { last: 'sixth_reel_x3_stop', first: 'sixth_reel_x2_stop' },
  3: { last: 'sixth_reel_x5_stop', first: 'sixth_reel_x3_stop' },
  4: { last: 'sixth_reel_x8_stop', first: 'sixth_reel_x5_stop' },
  5: { last: 'sixth_reel_bonus_stop', first: 'sixth_reel_x8_stop' },
};
