import { ResourceTypes } from '../resources.d';

export const introContents = [
  {
    img: ResourceTypes.intro01,
    txtKey: 'introTitle',
  },
  {
    img: ResourceTypes.intro02,
    txtKey: 'introTitle2',
  },
  {
    img: ResourceTypes.intro03,
    txtKey: 'introTitle3',
  },
];
