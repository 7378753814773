import { MAPPED_SYMBOLS_ANIMATIONS, SlotId, SymbolAnimationType } from '../../../config';

import { IAnimateSlot } from './IAnimateSlot';
import { SpineAnimateSlot } from './spineAnimateSlot';
import { SpriteSheetSlot } from './spriteSheetAnimateSlot';

export function animateSlotFactory(slotId: SlotId, positionPriority = 0): IAnimateSlot {
  const animationData = MAPPED_SYMBOLS_ANIMATIONS[slotId]!;

  switch (animationData.type) {
    case SymbolAnimationType.SPRITE:
      return new SpriteSheetSlot(slotId);
    case SymbolAnimationType.SPINE:
      return new SpineAnimateSlot(slotId, positionPriority);
    default:
      return new SpineAnimateSlot(slotId, positionPriority);
  }
}
