import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../../global.d';
import ViewContainer from '../../components/container';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, eventManager } from '../../config';

class SixthReelGear extends ViewContainer {
  private spine = new Spine(PIXI.Loader.shared.resources['sixth_reel_gear']!.spineData!);

  private isSpinning = false;

  private isForceStop = false;

  constructor() {
    super();

    this.spine.position.set(GAME_CONTAINER_WIDTH / 2, -GAME_CONTAINER_HEIGHT / 2 + 165);
    this.spine.state.setAnimation(0, 'sixth_reel_gear_idle', false);

    this.addChild(this.spine);

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.isForceStop = false;
      this.isSpinning = true;
      this.spine.state.setAnimation(0, 'sixth_reel_gear_idle', false);
      this.spine.state.setAnimation(0, 'sixth_reel_gear_right', true);
    });

    eventManager.addListener(EventTypes.STOP_SIX_REEL, () => {
      if (this.isForceStop) return;

      this.spine.state.setAnimation(0, 'sixth_reel_gear_stop', false);

      this.spine.state.clearListeners();
      this.spine.state.addListener({
        complete: () => {
          this.isSpinning = false;
          this.spine.state.clearListeners();
        },
      });
    });

    eventManager.addListener(EventTypes.RAIJIN_ATTACK, () => {
      if (this.isForceStop) return;

      this.spine.state.setAnimation(0, 'sixth_reel_gear_right_lightningstop', false);

      this.spine.state.clearListeners();
      this.spine.state.addListener({
        complete: () => {
          this.isSpinning = false;
          this.spine.state.clearListeners();
        },
      });
    });

    eventManager.addListener(EventTypes.FUJIN_ATTACK, () => {
      if (this.isForceStop) return;

      this.isSpinning = true;
      this.spine.state.setAnimation(0, 'sixth_reel_gear_left', false);
      this.spine.state.addAnimation(0, 'sixth_reel_gear_stop', false, 0);

      this.spine.state.clearListeners();
      this.spine.state.addListener({
        complete: () => {
          this.isSpinning = false;
          this.spine.state.clearListeners();
        },
      });
    });

    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      if (this.isSpinning) {
        this.isForceStop = true;
        this.isSpinning = false;
        this.spine.state.setAnimation(0, 'sixth_reel_gear_stop', false);
      }
    });

    eventManager.addListener(EventTypes.ROLLBACK_REELS, () => {
      this.spine.state.setAnimation(0, 'sixth_reel_gear_idle', false);
    });
  }
}

export default SixthReelGear;
