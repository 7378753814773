import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { lotteryColorChangeTables } from '../../anticipation/table';
import { getRand, getResultFromTable } from '../../anticipation/util';
import { ISongs } from '../../config';
import { setBetAmount } from '../../gql';
import { countStage, normalizeCoins } from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { WinStages } from '../config';

import {
  STOCK_ANIMATION_MOVE_DURATION,
  STOCK_COLOR_CHANGE_END_ROT_TARGET_RADIAN,
  STOCK_COLOR_CHANGE_START_ROT_TARGET_RADIAN,
  StockColor,
  StockColorState,
  easeOutQuad,
  stockAnimationNames,
  stockXPositions,
} from './config';

class Stock extends PIXI.Sprite {
  private spine: Spine;

  private colorState: StockColorState = 'Blank';

  private winStage: WinStages = WinStages.None;

  constructor(colorRank: StockColorState, position: number, scale: number) {
    super();

    this.spine = new Spine(PIXI.Loader.shared.resources['feature_stock']!.spineData!);

    this.x = stockXPositions[position]!;
    this.scale.set(scale);
    this.setColorState(colorRank);

    this.addChild(this.spine);
  }

  public getColorState(): StockColorState {
    return this.colorState;
  }

  public setColorState(color: StockColorState): void {
    this.colorState = color;
  }

  public setIdleAnimation() {
    if (this.colorState === 'Blank') {
      this.spine.visible = false;
      this.spine.state.setEmptyAnimation(0, 0);
    } else {
      this.spine.state.setAnimation(0, stockAnimationNames[this.colorState]!.idle, false);
      this.spine.visible = true;
    }
  }

  public setCurrentAnimation() {
    if (this.colorState !== 'Blank') {
      this.spine.state.setAnimation(0, stockAnimationNames[this.colorState]!.current, true);
      this.spine.visible = true;
    }
  }

  public setLoopAnimation() {
    if (this.colorState === 'Blank') {
      this.spine.visible = false;
      this.spine.state.setEmptyAnimation(0, 0);
    } else {
      this.spine.state.setAnimation(0, stockAnimationNames[this.colorState]!.loop, true);
      this.spine.visible = true;
    }
  }

  public setLoopAndIdleAnimation() {
    if (this.colorState === 'Blank') {
      this.spine.visible = false;
      this.spine.state.setEmptyAnimation(0, 0);
    } else {
      this.spine.state.setAnimation(0, stockAnimationNames[this.colorState]!.loop, false);
      this.spine.state.addAnimation(0, stockAnimationNames[this.colorState]!.idle, false, 0);
      this.spine.visible = true;
    }
  }

  public setWinStage(nextPayout = 0) {
    const betAmount = normalizeCoins(setBetAmount());
    const winAmount = normalizeCoins(nextPayout);
    const winStage = countStage(betAmount, winAmount);

    this.winStage = winStage;
  }

  public getWinStage() {
    return this.winStage;
  }

  public getMoveAnimation(duration: number, targetPos: number): Tween {
    const animation = new Tween({
      object: this,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: this.x,
      target: targetPos,
      easing: easeOutQuad,
    });

    animation.addOnSkip(() => {
      this.x = animation.target;
      animation.onComplete();
    });
    return animation;
  }

  public getFadeAnimation(duration: number, beginValue: number, endValue: number): Tween {
    const animation = new Tween({
      object: this,
      duration,
      property: TweenProperties.ALPHA,
      propertyBeginValue: beginValue,
      target: endValue,
    });

    animation.addOnSkip(() => {
      this.alpha = animation.target;
      animation.onComplete();
    });

    return animation;
  }

  public changeStockColor(colorState: StockColorState, index = 0, endTargetScale: number) {
    if (this.colorState === colorState) return;

    const animationChain = new AnimationChain();
    const startAnimation = this.getScaleAnimation(STOCK_ANIMATION_MOVE_DURATION * 0.5, 1, 0);
    const endAnimation = this.getScaleAnimation(STOCK_ANIMATION_MOVE_DURATION * 0.5, 0, endTargetScale);

    startAnimation.addAnimation(
      this.getRotationAnimation(STOCK_ANIMATION_MOVE_DURATION * 0.5, 0, STOCK_COLOR_CHANGE_START_ROT_TARGET_RADIAN),
    );
    endAnimation.addAnimation(
      this.getRotationAnimation(
        STOCK_ANIMATION_MOVE_DURATION * 0.5,
        STOCK_COLOR_CHANGE_START_ROT_TARGET_RADIAN,
        STOCK_COLOR_CHANGE_END_ROT_TARGET_RADIAN,
      ),
    );

    endAnimation.addOnStart(() => {
      this.setColorState(colorState);
      index === 0 && this.setCurrentAnimation();
      index !== 0 && this.setLoopAnimation();
    });

    animationChain.appendAnimation(startAnimation);
    animationChain.appendAnimation(endAnimation);

    AudioApi.play({ type: ISongs.SONG_021_20_Stock_Upgrade });
    animationChain.start();
  }

  private getRotationAnimation(duration: number, begin: number, target: number): Tween {
    const animation = new Tween({
      object: this.spine,
      duration,
      property: TweenProperties.ROTATION,
      propertyBeginValue: begin,
      target: target,
    });
    return animation;
  }

  public getScaleAnimation(duration: number, begin: number, target: number): AnimationGroup {
    const animationGroup = new AnimationGroup({});

    const scaleX = new Tween({
      object: this.spine.scale,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: begin,
      target: target,
    });
    const scaleY = new Tween({
      object: this.spine.scale,
      duration,
      property: TweenProperties.Y,
      propertyBeginValue: begin,
      target: target,
    });

    animationGroup.addAnimation(scaleX);
    animationGroup.addAnimation(scaleY);
    return animationGroup;
  }

  public lotColorRankChange(currentColor: StockColorState, winStage: WinStages): StockColorState {
    const resultTable: Record<number, StockColor> = {
      0: 'White',
      1: 'Blue',
      2: 'Red',
      3: 'Gold',
    };

    if (currentColor === 'Blank' || currentColor === 'Gold') {
      return currentColor;
    }

    const colorRank = currentColor;
    const rand = getRand();
    const result = getResultFromTable(lotteryColorChangeTables[colorRank][winStage]!, rand);

    return resultTable[result]!;
  }
}

export default Stock;
