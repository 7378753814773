import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, audioSpriteVolume } from '../../config';
import { BgmSoundTypes, EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setGameMode } from '../../gql/cache';
import { isFreeSpinMode } from '../../utils';
import { BackGroundSkin } from '../background/background';
import { SlotMachineState, eventManager } from '../config';

type BgmType = Record<BgmSoundTypes, { base: ISongs; melo?: ISongs }>;

export const bgmList: BgmType = {
  regular: { base: ISongs.SONG_021_01_BaseGameBGM_Base, melo: ISongs.SONG_021_02_BaseGameBGM_Melo },
  fs: { base: ISongs.SONG_021_03_FreeSpinBGM },
};

class BgmControl {
  private bgmListIndex: BgmSoundTypes;

  private background: BackGroundSkin | undefined;

  private meloFlg: boolean;

  private timer: NodeJS.Timeout | undefined;

  private isChangeRestriction = false;

  constructor() {
    this.bgmListIndex = BgmSoundTypes.BASE;
    this.background = undefined;
    this.meloFlg = false;
    this.timer = undefined;

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      if (this.isChangeRestriction) {
        this.playBgm();
      }
    });
  }

  private clearTimeout() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state === SlotMachineState.IDLE) {
      this.clearTimeout();

      if (this.meloFlg) {
        this.timer = setTimeout(() => {
          AudioApi.fadeOut(3000, bgmList[this.bgmListIndex].melo!);
        }, 30 * 1000);
      }
    } else if (state === SlotMachineState.SPIN) {
      this.clearTimeout();
    }
  }

  private onModeChange(settings: { mode: GameMode; background?: BackGroundSkin }) {
    const { mode } = settings;
    const bgmListIndex = this.getBgmListIndex(mode);

    this.meloFlg = false;
    this.playBgm(bgmListIndex);
  }

  private setBgmIndex(): void {
    if (isFreeSpinMode(setGameMode())) {
      this.bgmListIndex = BgmSoundTypes.FS;
    } else {
      this.bgmListIndex = BgmSoundTypes.BASE;
    }
  }

  private getBgmListIndex(mode: GameMode): BgmSoundTypes {
    return isFreeSpinMode(mode) ? BgmSoundTypes.FS : BgmSoundTypes.BASE;
  }

  public playBgmGameMode(mode: GameMode): void {
    const bgmListIndex = this.getBgmListIndex(mode);
    this.playBgm(bgmListIndex);
  }

  public playBgm(bgmListIndex?: BgmSoundTypes): void {
    if (setBrokenGame() && bgmListIndex === undefined) {
      return;
    }
    if (AudioApi.isRestricted) {
      return;
    }

    this.stopBgm();
    if (bgmListIndex === undefined) {
      this.setBgmIndex();
    } else {
      this.bgmListIndex = bgmListIndex;
    }
    AudioApi.play({ type: bgmList[this.bgmListIndex].base });
    if ('melo' in bgmList[this.bgmListIndex]) {
      AudioApi.play({
        type: bgmList[this.bgmListIndex].melo!,
        volume: 0,
      });
    }
  }

  public stopBgm(): void {
    AudioApi.stop({ type: bgmList[this.bgmListIndex].base });
    if ('melo' in bgmList[this.bgmListIndex]) {
      AudioApi.stop({
        type: bgmList[this.bgmListIndex].melo!,
        volume: 0,
      });
    }
  }

  public fadeInBase(fadeTime: number): void {
    AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex].base, audioSpriteVolume[bgmList[this.bgmListIndex].base]);
  }

  public fadeInMelo(fadeTime: number): void {
    if ('melo' in bgmList[this.bgmListIndex]) {
      const soundProp = AudioApi.getSoundByKey(bgmList[this.bgmListIndex].melo!);
      if (soundProp.volume !== 0) {
        return;
      }

      this.meloFlg = true;
      AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex].melo!, audioSpriteVolume[bgmList[this.bgmListIndex].melo!]);
    }
    // restart timer
    this.onSlotMachineStateChange(SlotMachine.getInstance().state);
  }

  public fadeOutAll(fadeTime: number): void {
    AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex].base);
    this.fadeOutMelo(fadeTime);
  }

  public fadeOutMelo(fadeTime: number): void {
    if ('melo' in bgmList[this.bgmListIndex]) {
      this.meloFlg = false;
      AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex].melo!);
    }
  }

  public handleChangeRestriction(): void {
    AudioApi.unSuspend();
    AudioApi.processRestriction({
      restricted: false,
    });
    if (setBrokenGame()) {
      this.isChangeRestriction = true;
      eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
    } else {
      this.playBgm();
    }
  }

  public handleUnSuspended(): void {
    const correctBgmIndex = this.getBgmListIndex(setGameMode());
    const isPlayingCorrectBgm = AudioApi.isPlaying(bgmList[correctBgmIndex].base);
    const isPlayingNowBgm = AudioApi.isPlaying(bgmList[this.bgmListIndex].base);

    console.log('handleUnSuspended');

    console.info({
      gameMode: setGameMode(),
      bgmListIndex: this.bgmListIndex,
      isPlayingNowBgm,
      isPlayingCorrectBgm,
    });

    if (correctBgmIndex !== this.bgmListIndex) {
      console.warn('wrong bgm');
      if (!isPlayingCorrectBgm) {
        console.warn('not playing modeBgm');
        this.playBgm();
      } else {
        this.bgmListIndex = correctBgmIndex;
      }
    }
  }
}

const bgmControl = new BgmControl();
export { bgmControl as BgmControl };
export default bgmControl;
