import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { EventTypes, SixReelSlotId, SixthReelResult } from '../../../global.d';
import { setGameMode, setIsSixthReel1stStopPosition, setIsSixthReelReverseSpin } from '../../../gql';
import { getRandomNumber } from '../../../utils';
import Animation from '../../animations/animation';
import AnimationChain from '../../animations/animationChain';
import Tween from '../../animations/tween';
import ViewContainer from '../../components/container';
import { SLOTS_CONTAINER_WIDTH, eventManager } from '../../config';

import {
  MAPPED_SIX_REEL_MULTIPLIER_POSITIONS,
  MAPPED_SIX_REEL_POSITIONS,
  SIXTH_FUJIN_ATTACK_ANIMATION_FRAME,
  SIXTH_RAIJIN_ATTACK_ANIMATION_FRAME,
  SIXTH_REEL_FRAME_HEIGHT,
  SIXTH_REVERSE_SPIN_LOTTERY_DENOMINATOR,
  SixReelIdleAnimationNames,
  SixReelPositionType,
  SixReelStopAnimationNames,
  sixReverseSpinLotteryValues,
} from './config';

class SixthReel extends ViewContainer {
  private spine: Spine = new Spine(PIXI.Loader.shared.resources['sixth_reel']!.spineData!);

  private spinAnimation: Animation | undefined;

  private stopAnimation: Animation | undefined;

  private isSpinning = false;

  private reelPosition: SixReelPositionType = 0;

  private isBonusStop = false;

  private isReverse = false;

  private spinResult: SixthReelResult = {
    mode: '',
    type: '',
    multiplier: 1,
  };

  constructor() {
    super();

    this.position.set(SLOTS_CONTAINER_WIDTH / 2, -SIXTH_REEL_FRAME_HEIGHT / 2);

    this.setIdleAnimation();
    this.addChild(this.spine);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.handleSpin.bind(this));
    eventManager.addListener(EventTypes.SETUP_SIXTH_REEL_POSITIONS, this.setupReelPosition.bind(this));
    eventManager.addListener(EventTypes.RAIJIN_ATTACK, this.handleRaijinAttack.bind(this));
    eventManager.addListener(EventTypes.STOP_SIX_REEL, () => {
      if (!this.isSpinning) return;
      this.startReelStopAnimation();
    });
    eventManager.addListener(EventTypes.REELS_STOPPED, this.handleReverseSpin.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.handleForceStop.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.handleForceStop.bind(this));
  }

  private setIdleAnimation() {
    this.spine.state.setAnimation(0, SixReelIdleAnimationNames[this.reelPosition], false);
  }

  private setStopAnimation(reelPosition: SixReelPositionType, isFirstStop: boolean): void {
    if (isFirstStop) {
      this.spine.state.setAnimation(0, SixReelStopAnimationNames[reelPosition].first, false);
    } else {
      this.spine.state.setAnimation(0, SixReelStopAnimationNames[reelPosition].last, false);
    }
  }

  private setSpinAnimation(isReverseRotate = false) {
    this.spine.state.setAnimation(0, isReverseRotate ? 'sixth_reel_spin_left' : 'sixth_reel_spin_right', true);
  }

  private onChangeMode = (settings: { sixReelResult?: SixthReelResult }): void => {
    if (!settings.sixReelResult) {
      this.reelPosition = 0;
    } else {
      const lastBaseGameSpinResult = settings.sixReelResult;
      if (lastBaseGameSpinResult.type === 'BONUS') {
        this.reelPosition = MAPPED_SIX_REEL_POSITIONS[SixReelSlotId.SP];
      } else {
        this.reelPosition = MAPPED_SIX_REEL_MULTIPLIER_POSITIONS[lastBaseGameSpinResult.multiplier];
      }
    }
    this.setIdleAnimation();
  };

  private handleSpin = (): void => {
    this.spinAnimation?.skip();
    this.spinAnimation = undefined;
    this.stopAnimation?.skip();
    this.stopAnimation = undefined;
    setIsSixthReelReverseSpin(false);

    this.createSpinAnimation().start();
    this.isSpinning = true;
    this.isReverse = false;
  };

  private lotteryRevereSpin = (): boolean => {
    const lotteryConditions = this.spinResult.multiplier > 2;
    if (!lotteryConditions) return false;

    let result = false;
    const lotteryValue = sixReverseSpinLotteryValues[setGameMode()]!;

    result = getRandomNumber(SIXTH_REVERSE_SPIN_LOTTERY_DENOMINATOR) < lotteryValue;
    //if (isFreeSpinMode(setGameMode())) {
    //  if (this.spinResult.multiplier > 2 || this.isBonusStop) {
    //    result = getRandomNumber(SIXTH_REVERSE_SPIN_LOTTERY_DENOMINATOR) < lotteryValue;
    //  }
    //} else {
    //  if (this.spinResult.multiplier > 2 || this.isBonusStop) {
    //    result = getRandomNumber(SIXTH_REVERSE_SPIN_LOTTERY_DENOMINATOR) < lotteryValue;
    //  }
    //}
    return result;
  };

  private setupReelPosition = (spinResult: SixthReelResult): void => {
    this.spinResult = spinResult;
    this.isBonusStop = false;

    if (this.spinResult.type === 'BONUS') {
      this.isBonusStop = true;
      this.reelPosition = MAPPED_SIX_REEL_POSITIONS[SixReelSlotId.SP];
    } else {
      this.reelPosition = MAPPED_SIX_REEL_MULTIPLIER_POSITIONS[this.spinResult.multiplier];
    }
    this.isReverse = this.lotteryRevereSpin();

    if (!this.isReverse) {
      setIsSixthReel1stStopPosition(this.reelPosition);
    } else {
      if (this.reelPosition) {
        setIsSixthReel1stStopPosition(this.reelPosition - 1);
      }
    }

    setIsSixthReelReverseSpin(this.isReverse);
  };

  private startReelStopAnimation() {
    const animation = new AnimationChain();
    const reelStopDelay = Tween.createDelayAnimation(SIXTH_RAIJIN_ATTACK_ANIMATION_FRAME);
    if (!this.isReverse) {
      animation.addOnSkip(() => {
        this.handleLastStop(this.reelPosition);
      });
      reelStopDelay.addOnComplete(() => {
        this.handleLastStop(this.reelPosition);
      });
    } else {
      animation.addOnSkip(() => {
        this.handle1stStop(this.reelPosition);
      });
      reelStopDelay.addOnComplete(() => {
        this.handle1stStop(this.reelPosition);
      });
    }
    animation.appendAnimation(reelStopDelay);
    animation.start();
  }

  private handleRaijinAttack = (): void => {
    if (!this.isSpinning) return;

    AudioApi.play({ type: ISongs.SONG_021_16_Thunder });

    this.startReelStopAnimation();
  };

  private handleReverseSpin = (): void => {
    if (!this.isReverse) return;
    if (!this.isSpinning) return;

    AudioApi.play({ type: ISongs.SONG_021_17_Wind });
    this.createSpinAnimation(true).start();

    const animation = new AnimationChain();
    const reelStopDelay = Tween.createDelayAnimation(SIXTH_FUJIN_ATTACK_ANIMATION_FRAME);

    animation.addOnSkip(() => {
      this.handleLastStop(this.reelPosition);
    });

    reelStopDelay.addOnComplete(() => {
      this.handleLastStop(this.reelPosition);
    });
    animation.appendAnimation(reelStopDelay);
    animation.start();
    this.stopAnimation = animation;

    eventManager.emit(EventTypes.FUJIN_ATTACK);
  };

  private handle1stStop = (reelPosition: SixReelPositionType): void => {
    this.stopReel(reelPosition, true);
  };

  private handleLastStop = (reelPosition: SixReelPositionType): void => {
    if (!this.isSpinning) return;
    this.stopReel(reelPosition);
    this.isSpinning = false;
  };

  private handleForceStop = (): void => {
    this.handleLastStop(this.reelPosition);
  };

  private stopReel(reelPosition: SixReelPositionType, isFirstStop = false) {
    this.spinAnimation?.skip();
    this.spinAnimation = undefined;
    this.setStopAnimation(reelPosition, isFirstStop);
    AudioApi.stop({ type: ISongs.SONG_021_18_6th_Spin });
    AudioApi.play({ type: ISongs.SONG_021_19_6th_Stop });
  }

  private createSpinAnimation(isReverseRotate?: boolean): Animation {
    const isLoop = !isReverseRotate;
    const animation = new Animation({ isLoop });
    animation.addOnStart(() => {
      AudioApi.play({ type: ISongs.SONG_021_18_6th_Spin });
      this.setSpinAnimation(isReverseRotate);
    });
    this.spinAnimation = animation;
    return animation;
  }
}

export default SixthReel;
