import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS_ANIMATIONS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setNextResult } from '../../gql/cache';
import { isScatter } from '../../utils';
import ViewContainer from '../components/container';
import {
  REELS_AMOUNT,
  REEL_HEIGHT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
} from '../config';
import { IWinLine, Icon } from '../d';

export class SlotsStopDisplayContainer extends ViewContainer {
  private stopSpines: Spine[] = [];

  private tints: PIXI.Graphics[] = [];

  constructor(spinResult: Icon[]) {
    super();

    this.sortableChildren = true;

    this.initStopSpines(spinResult);

    this.initTints();

    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.hideSpines.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.hideSpines.bind(this));
    eventManager.addListener(EventTypes.SHOW_WIN_LINES, this.onShowWinLines.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_LINES, this.onHideWinLines.bind(this));
  }

  private initStopSpines(spinResult: Icon[]) {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const id = i * REELS_AMOUNT + j;
        const slotId = spinResult[i * REELS_AMOUNT + j]!.id!;
        const spine = new Spine(PIXI.Loader.shared.resources[MAPPED_SYMBOLS_ANIMATIONS[slotId].src!]!.spineData!);
        spine.x = REEL_WIDTH * (id % REELS_AMOUNT) + SLOT_WIDTH / 2;
        spine.y = REEL_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
        spine.visible = false;

        this.addChild(spine);
        this.stopSpines.push(spine);
      }
    }
  }

  private initTints() {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const id = i * REELS_AMOUNT + j;
        const tint = new PIXI.Graphics();
        tint.beginFill(0, 0.5);
        tint.drawRect(0, 0, SLOT_WIDTH, SLOT_HEIGHT);
        tint.x = REEL_WIDTH * (id % REELS_AMOUNT) + SLOT_WIDTH / 2;
        tint.y = REEL_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
        tint.pivot.set(SLOT_WIDTH / 2, SLOT_HEIGHT / 2);
        tint.visible = false;

        this.addChild(tint);
        this.tints.push(tint);
      }
    }
  }

  private onReelStopped(reelId: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const index = i * REELS_AMOUNT + reelId;
      const slotId = setNextResult()?.bet.result.spinResult[index]!.id;
      const spine = this.stopSpines[index]!;
      spine.visible = true;
      spine.state.setAnimation(0, MAPPED_SYMBOLS_ANIMATIONS[slotId!].stop!, false);
    }
  }

  private onReelsStopped(): void {
    this.resetSpinesTint();
    this.stopSpines.forEach((spine) => {
      spine.state.setEmptyAnimation(0, 0);
      spine.visible = false;
    });
  }

  private isLowPaySymbol(slotId: SlotId): boolean {
    return slotId === SlotId.F || slotId === SlotId.G || slotId === SlotId.H;
  }

  private onShowWinLines(winLines: IWinLine[]) {
    const lowPayPositions = new Set<number>();
    this.tints.forEach((spine) => (spine.visible = true));

    winLines.forEach((line) => {
      if (line.lineId !== null) {
        line.winPositions.forEach((position) => {
          const slotId = setNextResult()?.bet.result.spinResult[position]!.id!;
          if (this.isLowPaySymbol(slotId)) {
            lowPayPositions.add(position);
            this.stopSpines[position]!.state.setEmptyAnimation(0, 0);
            this.stopSpines[position]!.visible = false;
          }
          this.tints[position]!.visible = false;
        });
      }
    });
    eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, Array.from(lowPayPositions), false);
  }

  private onHideWinLines(_hideWinLines: IWinLine[]) {
    const arr = [];
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) arr.push(i);
    this.tints.forEach((spine) => (spine.visible = false));
    eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, arr, true);
  }

  private onAnticipationStart(anticipationReelId: number) {
    this.tints.forEach((tint, index) => {
      const slotId = setNextResult()?.bet.result.spinResult[index]!.id!;
      const reelId = index % REELS_AMOUNT;
      if (isScatter(slotId) || reelId >= anticipationReelId) {
        tint.visible = false;
      } else {
        tint.visible = true;
      }
    });
  }

  private resetSpinesTint() {
    this.tints.forEach((tint) => (tint.visible = false));
  }

  private hideSpines(): void {
    this.resetSpinesTint();
    this.stopSpines.forEach((spine) => {
      spine.state.setEmptyAnimation(0, 0);
      spine.visible = false;
    });
  }
}
