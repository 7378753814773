import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql';
import { isFreeSpinMode } from '../../utils';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { fujinAttackEffectLayout } from './config';

class FujinAttackEffect extends ViewContainer {
  private effectSpine: Spine = new Spine(PIXI.Loader.shared.resources['Fujin_Effect']!.spineData!);

  private isPortrait = false;

  private window = { width: 0, height: 0 };

  constructor() {
    super();

    this.init();

    eventManager.addListener(EventTypes.FUJIN_ATTACK, () => {
      this.setAttackEffectAnimation(setGameMode());
    });

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.resizeGameContainer.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.resizeGameContainer.bind(this));
  }
  private setAttackEffectAnimation(mode: GameMode) {
    let animationName = mode === GameMode.FREE_SPINS ? 'fujin_attack_fg' : 'fujin_attack_bg';
    if (this.isPortrait) {
      animationName = 'fujin_attack_portrait';
    }
    this.effectSpine.state.setAnimation(0, animationName, false);
  }

  private init() {
    this.addChild(this.effectSpine);
  }

  private resize(width: number, height: number): void {
    this.window = { width, height };
  }

  public resizeGameContainer() {
    let layout = { x: 0, y: 0, scale: 0 };
    const mode = setGameMode();
    const { width, height } = this.window;

    if (isFreeSpinMode(mode)) {
      if (width > height) {
        layout = fujinAttackEffectLayout.freeSpins.landScape;
      } else {
        layout = fujinAttackEffectLayout.freeSpins.portrait;
      }
    } else {
      if (width > height) {
        layout = fujinAttackEffectLayout.baseGame.landScape;
      } else {
        layout = fujinAttackEffectLayout.baseGame.portrait;
      }
    }
    this.isPortrait = width < height;
    this.position.set(layout.x, layout.y);
    this.scale.set(layout.scale);
  }
}

export default FujinAttackEffect;
