export enum ResourceTypes {
  bgFuji = 'bgFuji',
  fgFuji = 'fgFuji',
  tomoe = 'tomoe',
  messagebanner = 'messagebanner',
  logo = 'logo',
  sparks = 'sparks',
  particle = 'particle',
  lineLarge = 'lineLarge',
  lineMedium = 'lineMedium',
  lineShort = 'lineShort',
  reelFrameBg = 'reelFrameBg',
  reelFrameFg = 'reelFrameFg',
  reelsBackBg = 'reelsBackBg',
  reelsBackFg = 'reelsBackFg',
  rightLineLarge = 'rightLineLarge',
  rightLineMedium = 'rightLineMedium',
  rightLineShort = 'rightLineShort',
  symbolA = 'symbolA',
  symbolABlur = 'symbolABlur',
  symbolB = 'symbolB',
  symbolBBlur = 'symbolBBlur',
  symbolC = 'symbolC',
  symbolCBlur = 'symbolCBlur',
  symbolD = 'symbolD',
  symbolDBlur = 'symbolDBlur',
  symbolE = 'symbolE',
  symbolEBlur = 'symbolEBlur',
  symbolF = 'symbolF',
  symbolFBlur = 'symbolFBlur',
  symbolG = 'symbolG',
  symbolGBlur = 'symbolGBlur',
  symbolH = 'symbolH',
  symbolHBlur = 'symbolHBlur',
  symbolSc = 'symbolSc',
  symbolWl = 'symbolWl',
  titlelogo = 'titlelogo',
  backdrop = 'backdrop',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  infoStockStage = 'infoStockStage',
  infoTopReelBonus = 'infoTopReelBonus',
  infoTopReelX1 = 'infoTopReelX1',
  infoTopReelX1X8 = 'infoTopReelX1X8',
  intro01 = 'intro01',
  intro02 = 'intro02',
  intro03 = 'intro03',
  introBg = 'introBg',
  introBgPortrait = 'introBgPortrait',
  stockA = 'stockA',
  stockB = 'stockB',
  stockC = 'stockC',
  stockD = 'stockD',
  stockE = 'stockE',
  stockStage = 'stockStage',
  stockStageBack = 'stockStageBack',
  textLogo = 'textLogo',
}
