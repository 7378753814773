import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, bonusIds } from '../../global.d';
import { setIsBuyFeaturePopupOpened, setIsBuyFeaturePurchased } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import { SpriteButton } from '../components/button';
import {
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  eventManager,
} from '../config';
import AutoResizeText from '../text/autoResizeText';

import { betValueStyleConfirm, buyFeatureConfirmStyle, buyFeatureTitleStyle, totalCostTextStyle } from './textStyles';

class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private totalCostValue: AutoResizeText;

  private okButton: SpriteButton;

  private cancelButton: SpriteButton;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();
    this.visible = false;
    this.popupBg = this.initPopupBg();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelButton = this.initCancelButton();
    this.okButton = this.initOkButton();
    this.interactive = true;
    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, (totalCost: string) => {
      this.handleOpen(totalCost);
    });
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.on(EventTypes.FORCE_CLOSE_BUY_FEATURE, () => {
      setIsBuyFeaturePopupOpened(false);
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.initTitle());
    this.addChild(this.initDescription());
    this.addChild(this.initTotalCostText());
    this.addChild(this.totalCostValue);
    this.addChild(this.okButton);
    this.addChild(this.cancelButton);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopupConfirm));
    popupBg.anchor.set(0.5);

    return popupBg;
  }

  private initTitle() {
    const title = new AutoResizeText(i18n.t<string>('buyFeatureTitle'), buyFeatureTitleStyle);
    title.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.x = FEATURE_POPUP_TITLE_POSITION_X;
    title.anchor.set(0.5, 0);
    updateTextScale(title, this.popupBg.width - 200, 300);

    return title;
  }

  private initDescription(): AutoResizeText {
    const textStyle = new PIXI.TextStyle({ ...buyFeatureConfirmStyle, align: 'center' });

    const description = new AutoResizeText(i18n.t<string>('buyFeatureConfirmTitle'), textStyle);
    description.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y + 520;
    description.anchor.set(0.5);
    updateTextScale(description, this.popupBg.width - 100, 250);

    return description;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t<string>('buyFeatureTotalCost'), totalCostTextStyle);
    totalCostText.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y;
    totalCostText.anchor.set(0.5);
    updateTextScale(totalCostText, this.popupBg.width - 100, 250);

    return totalCostText;
  }

  private initTotalCostValue(): AutoResizeText {
    const totalCostValue = new AutoResizeText('0', betValueStyleConfirm);
    totalCostValue.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y;
    totalCostValue.anchor.set(0.5);

    return totalCostValue;
  }

  private initCancelButton(): SpriteButton {
    const button = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureCancelBtn,
        hover: ResourceTypes.buyFeatureCancelBtnHover,
        press: ResourceTypes.buyFeatureCancelBtnPressed,
        disable: ResourceTypes.buyFeatureCancelBtnDisable,
      },
      () => {
        this.handleClose();
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
        AudioApi.play({ type: ISongs.SONG_SFX_UI_Close });
      },
    );
    button.position.set(FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X, FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y);
    button.anchor.set(0.5);

    return button;
  }

  private initOkButton(): SpriteButton {
    const button = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureOkBtn,
        hover: ResourceTypes.buyFeatureOkBtnHover,
        press: ResourceTypes.buyFeatureOkBtnPressed,
        disable: ResourceTypes.buyFeatureOkBtnDisable,
      },
      () => {
        this.okButton.interactive = false;
        this.cancelButton.interactive = false;
        AudioApi.play({ type: ISongs.SONG_SFX_BuyFeature });
        eventManager.emit(EventTypes.HANDLE_BUY_BONUS, bonusIds[GameMode.BUY_FEATURE]!);
        setIsBuyFeaturePurchased(true);
      },
    );
    button.position.set(FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X, FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y);
    button.anchor.set(0.5);

    return button;
  }

  public handleOpen(totalCost: string): void {
    this.visible = true;
    this.totalCostValue.text = totalCost;
    this.okButton.interactive = true;
    this.cancelButton.interactive = true;
  }

  public handleClose(): void {
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP);
    this.visible = false;
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (_width: number, _height: number, _x: number, _y: number, _scale: number): void => {
    this.handleResize(this.window.width, this.window.height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = SAFE_AREA_PORTRAIT_HEIGHT / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y;
    } else {
      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.y = SAFE_AREA_LANDSCAPE_HEIGHT / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y;
    }
  }
}

export default BuyFeaturePopupConfirm;
