import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  infoBuyFeatureDesc,
  infoFreeSpinFeatureDesc,
  infoFreeSpinFeatureDesc2,
  infoTopReelFeatureDesc,
} from '../../config/gameRules';
import { setSlotConfig } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import SlotMachine from '../../slotMachine';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const TopReelFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <section className={styles['feature']}>
        <h1 className={styles['title']}>{t('infoTopReelFeatureTitle')}</h1>
        <div className={styles['col']}>
          <div className={styles['infoTopReelX1']}>
            <p
              className={`${styles['p']}`}
              key={infoTopReelFeatureDesc[0]!}
              dangerouslySetInnerHTML={{ __html: t(infoTopReelFeatureDesc[0]!) }}
            />
            <img
              className={styles['infoTopReelX1-img']}
              draggable="false"
              src={Resources.getSource(ResourceTypes.infoTopReelX1)}
              alt=""
            />
          </div>
          <p
            className={`${styles['p']}`}
            key={infoTopReelFeatureDesc[1]!}
            dangerouslySetInnerHTML={{ __html: t(infoTopReelFeatureDesc[1]!) }}
          />
          <p
            className={`${styles['p']}`}
            key={infoTopReelFeatureDesc[2]!}
            dangerouslySetInnerHTML={{ __html: t(infoTopReelFeatureDesc[2]!) }}
          />
          <p
            className={`${styles['p']}`}
            key={infoTopReelFeatureDesc[3]!}
            dangerouslySetInnerHTML={{ __html: t(infoTopReelFeatureDesc[3]!) }}
          />
          <p
            className={`${styles['p']} ${styles['bottom-margin']}`}
            key={infoTopReelFeatureDesc[4]!}
            dangerouslySetInnerHTML={{ __html: t(infoTopReelFeatureDesc[4]!) }}
          />

          <p
            className={`${styles['p']} ${styles['center']}`}
            key={infoTopReelFeatureDesc[5]!}
            dangerouslySetInnerHTML={{ __html: t(infoTopReelFeatureDesc[5]!) }}
          />
          <div className={styles['infoTopReelMultiplier']}>
            <img
              className={styles['infoTopReelMultiplier-img']}
              draggable="false"
              src={Resources.getSource(ResourceTypes.infoTopReelX1X8)}
              alt=""
            />
          </div>

          <p
            className={`${styles['p']} ${styles['center']}`}
            key={infoTopReelFeatureDesc[6]!}
            dangerouslySetInnerHTML={{ __html: t(infoTopReelFeatureDesc[6]!) }}
          />
          <div className={styles['infoTopReelBonus']}>
            <img
              className={styles['infoTopReelBonus-img']}
              draggable="false"
              src={Resources.getSource(ResourceTypes.infoTopReelBonus)}
              alt=""
            />
          </div>
        </div>
      </section>
    </section>
  );
};

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation();

  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }

  return (
    <section className={styles['feature']}>
      <section className={styles['feature']}>
        <h1 className={styles['title']}>{t('infoFreeSpinFeatureTitle')}</h1>
        <div className={styles['col']}>
          {infoFreeSpinFeatureDesc.map((v) => (
            <p className={`${styles['p']}`} key={v} dangerouslySetInnerHTML={{ __html: t(v) }} />
          ))}
        </div>
        <div className={styles['infoStockStage']}>
          <img
            className={styles['infoStockStage__img']}
            draggable="false"
            src={Resources.getSource(ResourceTypes.infoStockStage)}
            alt="payLines"
          />
        </div>
        <div className={styles['col']}>
          {infoFreeSpinFeatureDesc2.map((v) => (
            <p className={`${styles['p']}`} key={v} dangerouslySetInnerHTML={{ __html: t(v) }} />
          ))}
        </div>
      </section>
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation();
  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins['extract']!;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().infoBuyFeatureIcon));
  }, []);

  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoBuyFeatureTitle')}</h1>
      <div className={styles['buy_feature']}>
        <img className={styles['buy_feature__img']} draggable="false" src={infoBuyFeatureIcon} alt="buyFeatureBtn" />
      </div>
      <div className={styles['row']}>
        <div className={styles['col']}>
          {infoBuyFeatureDesc.map((v) => (
            <p className={`${styles['p']}`} key={v} dangerouslySetInnerHTML={{ __html: t(v) }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles['gameRules']}>
      <TopReelFeature />
      <FreeSpinFeature />
      <BuyFeatureFunction />
    </div>
  );
};

export default Features;
