import { makeVar } from '@apollo/client';

import { getUserConfig } from '@phoenix7dev/utils-fe';

import { SlotId } from '../config';
import {
  BonusStatus,
  FreeRoundBonus,
  GameMode,
  IBonus,
  ISettledBet,
  IUserBalance,
  PopupOpeningTypes,
  UserBonus,
  reelSets,
} from '../global.d';

import { IBet, IConfig, ISlotConfig, IStressful } from './d';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setIsSoundOn = makeVar<boolean>(getUserConfig<IConfig>('config', 'isSoundOn', true));

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setBrokenGame = makeVar<boolean>(false);

export const setBrokenBuyFeature = makeVar<boolean>(false);

export const setPrevReelsPosition = makeVar<number[]>([0, 0, 0, 0, 0]);

export const setUserLastBetResult = makeVar<IBet>({
  id: '',
  coinAmount: 1,
  coinValue: 1,
  result: {
    reelPositions: [],
    winCoinAmount: 0,
  },
  reelSetId: '',
  createdAt: '',
  updatedAt: '',
});

export const setIsFreeSpinsWin = makeVar<boolean>(false);

export const setIsSpinInProgress = makeVar<boolean>(false);

export const setIsMobile = makeVar<boolean>(false);

export const setIsSlotBusy = makeVar<boolean>(false);

export const setIsProcessToGame = makeVar<boolean>(false);

export const setBetAmount = makeVar<number>(1);

export const setCurrentFreeSpinsTotalWin = makeVar<number>(0);

export const setCoinValue = makeVar<number>(1);

export const setCoinAmount = makeVar<number>(1);

export const setWinAmount = makeVar<number>(0);

export const setLastRegularWinAmount = makeVar<number>(0);

export const setAutoSpinsAmount = makeVar<number>(0);

export const setStopOnWinExceeds = makeVar<number>(0);

export const setStopOnWinExceedsSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncreaseSliderValue = makeVar<number>(1);

export const setStopOnBalanceIncrease = makeVar<number>(0);

export const setStopOnBalanceDecrease = makeVar<number>(0);

export const setIsContinueAutoSpinsAfterFeature = makeVar<boolean>(false);

export const setIsStopOnAnyWin = makeVar<boolean>(false);

export const setIsStopOnFeatureWin = makeVar<boolean>(true);

export const setIsStopOnWinExceeds = makeVar<boolean>(false);

export const setIsStopOnBalanceIncrease = makeVar<boolean>(false);

export const setIsStopOnBalanceDecrease = makeVar<boolean>(false);

export const setIsAutoSpins = makeVar<boolean>(false);

export const setAutoSpinsLeft = makeVar<number>(0);

// todo replace with real backend logic
export const setFreeSpinsTotalWin = makeVar<number>(0);

export const setGameMode = makeVar<GameMode>(GameMode.REGULAR);

export const setCurrency = makeVar<string>('FUN');

export const setUserBalance = makeVar<IUserBalance>({
  balance: { amount: 0, currency: '' },
  clientId: '',
  id: '',
});

export const setReelSetId = makeVar<string>('');

export const setNextResult = makeVar<ISettledBet | null>(null);

export const setCurrentBonusId = makeVar<string>('');

export const setCurrentBonus = makeVar<UserBonus>({
  id: '',
  bonusId: '',
  betId: '',
  status: BonusStatus.INACTIVE,
  coinValue: 1,
  coinAmount: 1,
  rounds: 10,
  data: {
    count: 0,
    maxRounds: 0,
    storeCoinValue: false,
    debitMultiplier: 0,
    storeCoinAmount: false,
    creditMultiplier: 0,
    frbReferenceId: null,
    betId: '',
  },
  totalWinAmount: 0,
  bonus: {
    id: '',
    slotId: '',
    reelSetId: '',
    lineSetId: '',
    type: '',
    coinAmount: 1,
    purchasable: false,
    rounds: 0,
    data: {},
  },
  reelSetId: reelSets[GameMode.REGULAR]!,
  isActive: false,
  currentRound: 0,
  roundsPlayed: 0,
});

export const setAutoSpinsStartBalance = makeVar<number>(0);

export const setSlotConfig = makeVar<ISlotConfig>({
  id: '',
  lineSet: {
    id: '',
    slotId: '',
    lines: [],
    coinAmountMultiplier: 0,
  },
  clientSettings: {
    coinAmounts: {
      default: [],
      quick: [],
    },
    coinValues: [],
    features: [],
    autoplay: {
      options: [],
      conditions: {
        stopOnAnyWin: {
          enabled: false,
        },
        stopIfFeatureIsWon: {
          enabled: false,
        },
        stopIfSingleWinExceeds: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceDecreasesBy: {
          enabled: false,
          multipliers: [],
        },
        stopIfBalanceIncreasesBy: {
          enabled: false,
          multipliers: [],
        },
      },
    },
  },
  lines: [],
  sessionId: '',
  reels: [],
  icons: [],
  winLines: [],
  settings: {
    startPosition: [],
  },
  isBuyFeatureEnabled: true,
});

export const setBonuses = makeVar<IBonus[]>([]);

export const setGameHistory = makeVar<boolean[]>([false, false, false]);

export const setIsTurboSpin = makeVar<boolean>(getUserConfig<IConfig>('config', 'isTurboSpin', false));

export const setStressful = makeVar<IStressful>({
  show: false,
  type: 'none',
  message: '',
});

export const setIsBuyFeaturePopupOpened = makeVar<boolean>(false);

export const setIsDuringBigWinLoop = makeVar<boolean>(false);

export const setIsSoundLoading = makeVar<boolean>(false);

export const setIsOpenedMessageBanner = makeVar<boolean>(false);

export const setReel = makeVar<SlotId[][]>([]);

export const setIsPopupOpened = makeVar<boolean>(false);

export const setIsOpenInfoPopup = makeVar<boolean>(false);

export const setIsOpenHistoryPopup = makeVar<boolean>(false);

export const setIsOpenBetSettingsPopup = makeVar<boolean>(false);

export const setIsOpenAutoplayPopup = makeVar<boolean>(false);

export const setIsPopupOpeningInProgress = makeVar<PopupOpeningTypes>(PopupOpeningTypes.NONE);

export const setIsSixthReelReverseSpin = makeVar<boolean>(false);

export const setIsSixthReel1stStopPosition = makeVar<number>(0);

export const setIsDuringWinLineAnimation = makeVar<boolean>(false);

export const setIsDuringWinCountUpAnimation = makeVar<boolean>(false);

export const setIsFadeOut = makeVar<boolean>(false);

export const setIsBuyFeaturePurchased = makeVar<boolean>(false);

export const setIsTimeoutErrorMessage = makeVar(false);

export const setFreeRoundBonus = makeVar<FreeRoundBonus>({
  id: '',
  isActive: false,
  coinAmount: 1,
  coinValue: 1,
  bonusId: '',
  roundsPlayed: 0,
  status: BonusStatus.INACTIVE,
  currentRound: 0,
  rounds: 1,
  totalWinAmount: 0,
});
export const setIsFreeRoundBonus = makeVar<boolean>(false);
export const setIsPopupFreeRoundsOpened = makeVar<boolean>(false);
export const setFreeRoundsTotalWin = makeVar<number>(0);
export const setIsInTransition = makeVar<boolean>(false);
