import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode, ISettledBet } from '../../global.d';
import { setGameMode } from '../../gql';
import { getWinStage, isFreeSpinMode } from '../../utils';
import ViewContainer from '../components/container';
import { WinStages, eventManager } from '../config';

import { fujinLayout } from './config';

class Fujin extends ViewContainer {
  private spine: Spine = new Spine(PIXI.Loader.shared.resources['Fujin']!.spineData!);

  private isPortrait = false;

  private isDuringWinAnimation = false;

  constructor() {
    super();

    this.init();

    eventManager.addListener(EventTypes.FUJIN_ATTACK, () => {
      this.setAttackAnimation(setGameMode());
    });

    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SKIP_ALL_WIN_ANIMATIONS, () => {
      if (this.isDuringWinAnimation) {
        this.setIdleAnimation(setGameMode());
        this.isDuringWinAnimation = false;
      }
    });
    eventManager.addListener(EventTypes.CHANGE_MODE, (param: { mode: GameMode }) => {
      this.setIdleAnimation(param.mode);
    });

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, (param: { mode: GameMode }) => {
      this.setIdleAnimation(param.mode);
    });
  }

  private setIdleAnimation(mode: GameMode) {
    this.spine.state.setAnimation(0, mode === GameMode.FREE_SPINS ? 'fujin_idle_fg' : 'fujin_idle_bg', true);
  }

  private setAttackAnimation(mode: GameMode) {
    let animationName = mode === GameMode.FREE_SPINS ? 'fujin_attack_fg' : 'fujin_attack_bg';
    if (this.isPortrait) {
      animationName = 'fujin_attack_fg_portrait';
    }
    this.spine.state.setAnimation(0, animationName, false);
    this.spine.state.addAnimation(0, mode === GameMode.FREE_SPINS ? 'fujin_idle_fg' : 'fujin_idle_bg', true, 0);
  }

  private init() {
    this.setIdleAnimation(setGameMode());

    this.addChild(this.spine);
  }

  private startWinAnimation(nextResult: ISettledBet) {
    const winStage = getWinStage(nextResult);
    if (winStage >= WinStages.BigWin) {
      this.setWinAnimation();
    }
  }

  private setWinAnimation() {
    const animationName = 'fujin_win';
    this.spine.state.setAnimation(0, animationName, true);
    this.isDuringWinAnimation = true;
  }

  public gameContainerResize(mode: GameMode, width: number, height: number) {
    let layout = { x: 0, y: 0, scale: 0 };

    if (isFreeSpinMode(mode)) {
      if (width > height) {
        layout = fujinLayout.freeSpins.landScape;
      } else {
        layout = fujinLayout.freeSpins.portrait;
      }
    } else {
      if (width > height) {
        layout = fujinLayout.baseGame.landScape;
      } else {
        layout = fujinLayout.baseGame.portrait;
      }
    }
    this.isPortrait = width < height;
    this.position.set(layout.x, layout.y);
    this.scale.set(layout.scale);
  }
}

export default Fujin;
