import { Variables } from '../../config';

export const STOCK_MAX_DISPLAYED_NUM = 10;

export const STOCK_SPACE_2ND_POS_OFFSET_X = 110;
export const STOCK_SPACE_AFTER_3RD_POS_OFFSET_X = 86;

export const STOCK_OVER_TEXT_WIDTH = 65;
export const STOCK_OVER_TEXT_HEIGHT = 65;

export const STOCK_ANIMATION_FADE_DURATION = 300;
export const STOCK_ANIMATION_MOVE_DURATION = 500;

export const STOCK_DEFAULT_SCALE = 0.6;
export const STOCK_CURRENT_SCALE = 0.8;

export const STOCK_COLOR_CHANGE_START_ROT_TARGET_RADIAN = 180 * (Math.PI / 180);
export const STOCK_COLOR_CHANGE_END_ROT_TARGET_RADIAN = 360 * (Math.PI / 180);

export function easeOutQuad(x: number): number {
  return 1 - (1 - x) * (1 - x);
}

export type StockColorState = 'Blank' | 'White' | 'Blue' | 'Red' | 'Gold';

export type StockColor = 'White' | 'Blue' | 'Red' | 'Gold';

export type StockLotteryColor = 'White' | 'Blue' | 'Red';

export const stockAnimationNames: Record<StockColor, { idle: string; loop: string; current: string }> = {
  White: { idle: 'feature_stock_black_idle', loop: 'feature_stock_black_idle', current: 'feature_stock_black_current' },
  Blue: { idle: 'feature_stock_blue_idle', loop: 'feature_stock_blue', current: 'feature_stock_blue_current' },
  Red: { idle: 'feature_stock_red_idle', loop: 'feature_stock_red', current: 'feature_stock_red_current' },
  Gold: { idle: 'feature_stock_gold_idle', loop: 'feature_stock_gold', current: 'feature_stock_gold_current' },
};

export type StockObjectsPriorityType =
  | 'BACK_STAGE'
  | 'STOCK_BALLS'
  | 'OVER_COUNT_TEXT'
  | 'FRONT_STAGE'
  | 'CURRENT_STOCK_EFFECT'
  | 'ADD_STOCK_EFFECT'
  | 'ADD_EFFECT';

export const StockObjectsPriorities: Record<StockObjectsPriorityType, number> = {
  BACK_STAGE: 0,
  STOCK_BALLS: 1,
  OVER_COUNT_TEXT: 2,
  FRONT_STAGE: 3,
  CURRENT_STOCK_EFFECT: 4,
  ADD_STOCK_EFFECT: 5,
  ADD_EFFECT: 6,
};

export const stockXPositions: number[] = [
  0,
  STOCK_SPACE_2ND_POS_OFFSET_X,
  STOCK_SPACE_2ND_POS_OFFSET_X + STOCK_SPACE_AFTER_3RD_POS_OFFSET_X * 1,
  STOCK_SPACE_2ND_POS_OFFSET_X + STOCK_SPACE_AFTER_3RD_POS_OFFSET_X * 2,
  STOCK_SPACE_2ND_POS_OFFSET_X + STOCK_SPACE_AFTER_3RD_POS_OFFSET_X * 3,
  STOCK_SPACE_2ND_POS_OFFSET_X + STOCK_SPACE_AFTER_3RD_POS_OFFSET_X * 4,
  STOCK_SPACE_2ND_POS_OFFSET_X + STOCK_SPACE_AFTER_3RD_POS_OFFSET_X * 5,
  STOCK_SPACE_2ND_POS_OFFSET_X + STOCK_SPACE_AFTER_3RD_POS_OFFSET_X * 6 + 2,
  STOCK_SPACE_2ND_POS_OFFSET_X + STOCK_SPACE_AFTER_3RD_POS_OFFSET_X * 7 + 2,
  STOCK_SPACE_2ND_POS_OFFSET_X + STOCK_SPACE_AFTER_3RD_POS_OFFSET_X * 8 + 2,
  STOCK_SPACE_2ND_POS_OFFSET_X + STOCK_SPACE_AFTER_3RD_POS_OFFSET_X * 9, // STOCK_MAX_DISPLAYED_NUM
];

export const GRADIENT_STOCK_OVER_TEXT_COLOR_CONFIG = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#e7194b', '#f19e00', '#feff17', '#f19e00', '#e7194b'],
  fillGradientStops: [0.3, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowColor: 0x333333,
  dropShadowDistance: 3,
  fontSize: 55,
  miterLimit: 0,
  strokeThickness: 4,
};
