import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql';
import ViewContainer from '../components/container';
import { ParticleEmitterContainer } from '../components/particle';
import { eventManager } from '../config';

import {
  BACKGROUND_BASE_GAME_GRADIENT,
  BACKGROUND_FREE_SPINS_GRADIENT,
  BACKGROUND_SIZE_HEIGHT,
  BACKGROUND_SIZE_WIDTH,
  backGroundGameModeSkins,
} from './config';
import { glitterConfig } from './glitterConfig';

export type BackGroundSkin = 'base' | 'freeSpin';

class Background extends ViewContainer {
  private spines: Record<BackGroundSkin, Spine> = {
    base: new Spine(PIXI.Loader.shared.resources['Ambient_Cloud_BG']!.spineData!),
    freeSpin: new Spine(PIXI.Loader.shared.resources['Ambient_Cloud_FG']!.spineData!),
  };

  private spinesContainer = new PIXI.Container();

  private gradientTexture: Record<BackGroundSkin, PIXI.Texture>;

  private gradientSprite = new PIXI.Sprite();

  private glitterEffect: ParticleEmitterContainer;

  private currentSkin?: BackGroundSkin;

  constructor(skin: BackGroundSkin = 'base') {
    super();
    this.sortableChildren = true;

    this.glitterEffect = new ParticleEmitterContainer(glitterConfig);
    this.glitterEffect.zIndex = 10;
    this.addChild(this.glitterEffect);

    this.gradientTexture = {
      base: this.createGradient(BACKGROUND_SIZE_WIDTH, BACKGROUND_SIZE_HEIGHT, BACKGROUND_BASE_GAME_GRADIENT),
      freeSpin: this.createGradient(BACKGROUND_SIZE_WIDTH, BACKGROUND_SIZE_HEIGHT, BACKGROUND_FREE_SPINS_GRADIENT),
    };

    this.gradientSprite.anchor.set(0.5);
    this.addChild(this.gradientSprite);

    this.setSkin(skin);

    this.initSpines();
    for (const key in this.spines) {
      this.spinesContainer.addChild(this.spines[key as BackGroundSkin]);
    }
    this.addChild(this.spinesContainer);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  private initSpines(): void {
    const animationName: Record<BackGroundSkin, string> = {
      base: 'basegame_bg',
      freeSpin: 'freegame_bg',
    };
    for (const key in this.spines) {
      this.spines[key as BackGroundSkin]!.state.setAnimation(0, animationName[key as BackGroundSkin], true);
      this.spines[key as BackGroundSkin]!.state.addListener({
        event: (_t, e) => {
          if (setGameMode() === GameMode.FREE_SPINS && e.data.name === 'lightning') {
            AudioApi.stop({ type: ISongs.SONG_021_23_Thunder_FS });
            AudioApi.play({ type: ISongs.SONG_021_23_Thunder_FS });
          }
        },
      });
    }
  }

  private createGradient(width: number, height: number, gradients: string[]) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gradient = ctx!.createLinearGradient(0, 0, 0, height);

    canvas.setAttribute('width', width.toString());
    canvas.setAttribute('height', height.toString());

    gradient.addColorStop(0, gradients[0]!);
    gradient.addColorStop(0.25, gradients[1]!);
    gradient.addColorStop(0.85, gradients[2]!);
    gradient.addColorStop(1, gradients[3]!);

    ctx!.fillStyle = gradient;
    ctx!.fillRect(0, 0, width, height);

    return PIXI.Texture.from(canvas);
  }

  public setSkin(skinName: BackGroundSkin) {
    if (this.currentSkin === skinName) return;
    this.currentSkin = skinName;

    if (skinName === 'base') {
      this.glitterEffect.stop(true);
    } else if (skinName === 'freeSpin') {
      this.glitterEffect.start();
    }

    this.gradientSprite.texture = this.gradientTexture[skinName]!;

    for (const key in this.spines) {
      this.spines[key as BackGroundSkin]!.visible = key === skinName;
    }
  }

  private onChangeMode(settings: { mode: GameMode; background?: BackGroundSkin }) {
    this.setSkin(backGroundGameModeSkins[settings.mode]!);
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const fullScreenSize = { width: BACKGROUND_SIZE_WIDTH, height: BACKGROUND_SIZE_HEIGHT };
    const bgAspectRatio = fullScreenSize.width / fullScreenSize.height;
    const nowAspectRatio = width / height;

    let scale = 1.0;
    if (bgAspectRatio > nowAspectRatio) {
      scale = height / fullScreenSize.height;
    } else {
      scale = width / fullScreenSize.width;
    }

    this.gradientSprite.scale.set(scale);
    this.glitterEffect.scale.set(scale);

    if (width > height) {
      this.spinesContainer.pivot.set(0, 0);
      this.spinesContainer.scale.set(scale);
      this.spinesContainer.x = 0;
      this.spinesContainer.y = 0;
    } else {
      const mountFUjiSize = { width: 1975, height: 1100 };
      scale *= 0.4;
      this.spinesContainer.pivot.set(mountFUjiSize.width / 2, mountFUjiSize.height / 2);
      this.spinesContainer.scale.set(scale, scale);
      this.spinesContainer.position.x = width / 2;
      this.spinesContainer.position.y = height / 2;
    }
  }
}
export default Background;
