import { WinStages } from '../slotMachine/config';
import { StockLotteryColor } from '../slotMachine/stock/config';

// Current Color White
const LOT_CURRENT_WHITE_BASE_WIN = [100, 100, 100, 100]; // W:100%,B:0%,R:0%,G:0%
const LOT_CURRENT_WHITE_BIG_WIN = [50, 100, 100, 100]; // W:50%,B:50%,R:0%,G:0%
const LOT_CURRENT_WHITE_MEGA_WIN = [30, 80, 100, 100]; // W:30%,B:50%,R:20%,G:0%
const LOT_CURRENT_WHITE_GREAT_WIN = [5, 55, 75, 100]; // W:5%,B:50%,R:20%,G:25%

// Current Color Blue
const LOT_CURRENT_BLUE_BIG_WIN = [0, 100, 100, 100]; // W:0%,B:100%,R:0%,G:0%
const LOT_CURRENT_BLUE_MEGA_WIN = [0, 50, 100, 100]; // W:0%,B:50%,R:50%,G:0%
const LOT_CURRENT_BLUE_GREAT_WIN = [0, 25, 75, 100]; // W:0%,B:25%,R:50%,G:25%

// Current Color Red
const LOT_CURRENT_RED_MEGA_WIN = [0, 0, 100, 100]; // W:0%,B:0%,R:100%,G:0%
const LOT_CURRENT_RED_GREAT_WIN = [0, 0, 20, 100]; // W:0%,B:0%,R:20%,G:80%

export const lotteryColorChangeTables: Record<StockLotteryColor, Record<WinStages, number[]>> = {
  White: {
    [WinStages.None]: LOT_CURRENT_WHITE_BASE_WIN,
    [WinStages.BaseWin]: LOT_CURRENT_WHITE_BASE_WIN,
    [WinStages.BigWin]: LOT_CURRENT_WHITE_BIG_WIN,
    [WinStages.MegaWin]: LOT_CURRENT_WHITE_MEGA_WIN,
    [WinStages.GreatWin]: LOT_CURRENT_WHITE_GREAT_WIN,
    [WinStages.EpicWin]: LOT_CURRENT_WHITE_GREAT_WIN,
  },
  Blue: {
    [WinStages.None]: LOT_CURRENT_BLUE_BIG_WIN,
    [WinStages.BaseWin]: LOT_CURRENT_BLUE_BIG_WIN,
    [WinStages.BigWin]: LOT_CURRENT_BLUE_BIG_WIN,
    [WinStages.MegaWin]: LOT_CURRENT_BLUE_MEGA_WIN,
    [WinStages.GreatWin]: LOT_CURRENT_BLUE_GREAT_WIN,
    [WinStages.EpicWin]: LOT_CURRENT_BLUE_GREAT_WIN,
  },
  Red: {
    [WinStages.None]: LOT_CURRENT_RED_MEGA_WIN,
    [WinStages.BaseWin]: LOT_CURRENT_RED_MEGA_WIN,
    [WinStages.BigWin]: LOT_CURRENT_RED_MEGA_WIN,
    [WinStages.MegaWin]: LOT_CURRENT_RED_MEGA_WIN,
    [WinStages.GreatWin]: LOT_CURRENT_RED_GREAT_WIN,
    [WinStages.EpicWin]: LOT_CURRENT_RED_GREAT_WIN,
  },
};
