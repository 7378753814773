import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setCurrentBonus } from '../../gql';
import i18n from '../../i18next';
import { isFreeSpinMode } from '../../utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import ViewContainer from '../components/container';
import { GAME_CONTAINER_HEIGHT, eventManager } from '../config';
import {
  FREE_SPINS_COUNTER_ANIMATION_DELAY,
  FREE_SPINS_COUNTER_ANIMATION_LOOP,
  FREE_SPINS_COUNTER_ANIMATION_SCALE,
  spinsStyle,
  textStyle,
} from '../gameView/config';
import AutoResizeText from '../text/autoResizeText';

export class FreeSpinsCounter extends ViewContainer {
  private titleText: AutoResizeText;

  private spinsText: AutoResizeText;

  private pulsAnimation: Animation | null = null;

  constructor() {
    super();

    this.titleText = this.initTitleText(i18n.t('freeSpins'));
    this.titleText.anchor.set(0, 0.5);
    this.titleText.x = 0;
    this.titleText.y = 35;
    this.spinsText = this.initSpinsText(10, 0);

    this.init();

    eventManager.addListener(
      EventTypes.UPDATE_FREE_SPINS_COUNT,
      (spins: number, curr: number, immediately: boolean): void => this.handleUpdate(spins, curr, immediately),
    );

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) => {
      this.changeVisible(settings.mode);
    });
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, (settings: { mode: GameMode }) => {
      this.changeVisible(settings.mode);
    });

    if (setBrokenGame()) {
      this.handleUpdate(setCurrentBonus().rounds, setCurrentBonus().currentRound, true);
    }
  }

  private init(): void {
    this.name = 'counter';
    this.addChild(this.titleText);
    this.addChild(this.spinsText);
    this.position.set(468 - this.width / 2, GAME_CONTAINER_HEIGHT + 100);
    this.visible = false;
  }

  private initTitleText(titleText: string): AutoResizeText {
    const text = new AutoResizeText(i18n.t<string>(titleText), textStyle);
    text.anchor.set(0, 0);
    return text;
  }

  private initSpinsText(spins: number, currentSpin: number): AutoResizeText {
    const spinsText = new AutoResizeText(this.getFormatSpins(spins, currentSpin), spinsStyle);
    spinsText.anchor.set(0, 0.5);
    spinsText.position.set(50 + this.titleText.width, spinsText.height / 2);

    return spinsText;
  }

  private handleUpdate(spins: number, currentSpin: number, immediately: boolean): void {
    this.spinsText.text = this.getFormatSpins(spins, currentSpin);

    if (immediately) return;
    if (!this.visible) return;

    this.pulsAnimation = createZoomAnimation(
      this.spinsText,
      FREE_SPINS_COUNTER_ANIMATION_SCALE,
      FREE_SPINS_COUNTER_ANIMATION_DELAY,
      FREE_SPINS_COUNTER_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  private getFormatSpins(spins: number, currentSpin: number): string {
    return `${currentSpin}/${spins}`;
  }

  private resize(width: number, height: number) {
    if (width > height) {
      //this.titleText.y = 35;
      //this.spinsText.y = 35;
    } else {
      //this.titleText.y = -85;
      //this.spinsText.y = -85;
    }
  }

  private changeVisible(mode: GameMode) {
    this.visible = isFreeSpinMode(mode);
  }
}
