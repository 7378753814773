import { SlotId } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setIsDuringWinLineAnimation, setNextResult } from '../../gql';
import { isScatter } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  REELS_AMOUNT,
  REEL_HEIGHT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
} from '../config';
import { IWinLine, Icon } from '../d';
import { IAnimateSlot, animateSlotFactory } from '../slot/animateSlot';

class SlotsAnimationContainer extends ViewContainer {
  private slotsSymbols: IAnimateSlot[] = [];

  private animation: Animation | null = null;

  constructor() {
    super();
    this.sortableChildren = true;

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpinAnimation.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinAnimations.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(
      EventTypes.START_ADD_FREE_SPINS_SYMBOL_ANIMATION,
      this.onStartAddFreeSpinsSymbolAnimation.bind(this),
    );
  }

  private cleanSymbols() {
    this.slotsSymbols.forEach((symbol) => {
      symbol.skip();
    });
    this.removeChild(...this.slotsSymbols);
    this.slotsSymbols = [];
  }

  private initSymbols(spinResult: Icon[]) {
    this.cleanSymbols();

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const id = i * REELS_AMOUNT + j;
        const symbol = animateSlotFactory(spinResult[i * REELS_AMOUNT + j]!.id, j * SLOTS_PER_REEL_AMOUNT + i);
        symbol.x = REEL_WIDTH * (id % REELS_AMOUNT) + SLOT_WIDTH / 2;
        symbol.y = REEL_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
        this.addChild(symbol);
        this.slotsSymbols.push(symbol);
        symbol.visible = false;
      }
    }
  }

  private onStartSpinAnimation() {
    this.slotsSymbols.forEach((symbol) => {
      this.removeChild(symbol);
    });
  }

  private onAnticipationStart() {
    this.slotsSymbols.forEach((slot) => {
      if (!isScatter(slot.slotId)) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      } else {
        slot.zIndex = 3;
      }
    });
  }

  private onReelStopped(reelId: number) {
    if (reelId === 0) {
      this.initSymbols(setNextResult()!.bet.result.spinResult);
    }
  }

  private onStartAddFreeSpinsSymbolAnimation(nextResult: ISettledBet) {
    const { paylines } = nextResult;
    const validLines: IWinLine[] = [];

    this.initSymbols(nextResult!.bet.result.spinResult);

    const set = new Set<number>();
    paylines.forEach((payline) => {
      if (this.isWinPayLine(payline)) {
        let scatter = 0;
        payline.winPositions.forEach((position) => {
          if (
            nextResult!.bet.result.spinResult[position]?.id === SlotId.SC1 ||
            nextResult!.bet.result.spinResult[position]?.id === SlotId.SC2
          ) {
            set.add(position);
            scatter += 1;
          }
        });
        if (scatter) {
          validLines.push(payline);
        }
      }
    });

    if (validLines.length === 0) {
      return;
    }

    const animation = this.highlightSlots(Array.from(set));

    animation.start();
  }

  private onStartWinAnimation(nextResult: ISettledBet) {
    this.showWin(nextResult);
  }

  private resetSlotsTint() {
    this.slotsSymbols.forEach((slot) => (slot.tint = 0xffffff));
  }

  private skipWinAnimations() {
    this.animation?.skip();
    this.cleanSymbols();
  }

  private isWinPayLine = (payline: { lineId: number; winPositions: number[]; amount: number }) => {
    if (!payline.winPositions) return false;
    if (payline.winPositions.length === 0) return false;

    return !payline.winPositions.findLast(
      (winPosition) => winPosition >= setNextResult()!.bet.result.spinResult.length,
    );
  };

  private showWin(nextResult: ISettledBet) {
    const { paylines } = nextResult;
    const validPayLines: IWinLine[] = [];
    const animation = new AnimationChain();

    paylines.forEach((payline) => {
      if (this.isWinPayLine(payline)) {
        validPayLines.push(payline);
      }
    });

    animation.addOnSkip(() => {
      this.animation = null;
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      this.endOneCycleAnimation();
    });

    const set = new Set<number>();
    validPayLines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        set.add(position);
      });
    });

    if (set.size === 0) {
      console.warn('no win payline');
      return;
    }

    if (this.slotsSymbols.length === 0) {
      console.warn('no slotsSymbols');
      return;
    }

    setIsDuringWinLineAnimation(true);

    const allHighLightSlots = this.highlightSlots(Array.from(set));
    allHighLightSlots.addOnStart(() => {
      if (animation) {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, validPayLines);
      }
    });
    allHighLightSlots.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, validPayLines);
      if (paylines.length === 1) {
        this.endOneCycleAnimation();
      }
    });
    animation.appendAnimation(allHighLightSlots);

    const eachHighlightSlots = this.createHighlightChainAnimation(validPayLines, true);
    animation.appendAnimation(eachHighlightSlots);

    animation.start();

    this.animation = animation;
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((position) => {
      animationGroup.addAnimation(this.slotsSymbols[position]!.getWinAnimation());
    });
    return animationGroup;
  }

  private createHighlightChainAnimation(paylines: IWinLine[], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });

    paylines.forEach((payline, index) => {
      const chain = this.highlightSlots(payline.winPositions);
      chain.addOnStart(() => {
        if (this.animation) {
          eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        }
      });
      chain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);

        if (index === paylines.length - 1) {
          this.endOneCycleAnimation();
        }
      });
      animationChain.appendAnimation(chain);
      //}
    });
    return animationChain;
  }

  private endOneCycleAnimation() {
    if (!setIsDuringWinLineAnimation()) return;
    setIsDuringWinLineAnimation(false);
    eventManager.emit(EventTypes.WIN_LINE_ANIM_END);
  }
}

export default SlotsAnimationContainer;
