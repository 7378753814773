import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.H]: ResourceTypes.symbolH,
  [SlotId.WL]: ResourceTypes.symbolWl,
  [SlotId.SC1]: ResourceTypes.symbolSc,
  [SlotId.SC2]: ResourceTypes.symbolSc,
};

export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolABlur,
  [SlotId.B]: ResourceTypes.symbolBBlur,
  [SlotId.C]: ResourceTypes.symbolCBlur,
  [SlotId.D]: ResourceTypes.symbolDBlur,
  [SlotId.E]: ResourceTypes.symbolEBlur,
  [SlotId.F]: ResourceTypes.symbolFBlur,
  [SlotId.G]: ResourceTypes.symbolGBlur,
  [SlotId.H]: ResourceTypes.symbolHBlur,
  [SlotId.WL]: ResourceTypes.symbolWl,
  [SlotId.SC1]: ResourceTypes.symbolSc,
  [SlotId.SC2]: ResourceTypes.symbolSc,
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    win?: string;
    stop?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_a_win',
    stop: 'symbol_a_announce',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_b_win',
    stop: 'symbol_b_announce',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_c_win',
    stop: 'symbol_c_announce',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_d_win',
    stop: 'symbol_d_announce',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_e_win',
    stop: 'symbol_e_announce',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_f_win',
    stop: 'symbol_f_announce',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_g_win',
    stop: 'symbol_g_announce',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_h_win',
    stop: 'symbol_h_announce',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_wl_win',
    stop: 'symbol_wl_announce',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_sc_win',
    stop: 'symbol_sc_announce',
  },
  [SlotId.SC2]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    win: 'symbol_sc_win',
    stop: 'symbol_sc_announce',
  },
};

export const excerptWinBackSymbols = [SlotId.SC1, SlotId.SC2, SlotId.WL];

export const LOADER_MAPPED_SYMBOLS: IAddOptions[] = [
  ResourceTypes.symbolA,
  ResourceTypes.symbolB,
  ResourceTypes.symbolC,
  ResourceTypes.symbolD,
  ResourceTypes.symbolE,
  ResourceTypes.symbolF,
  ResourceTypes.symbolG,
  ResourceTypes.symbolH,
  ResourceTypes.symbolWl,
  ResourceTypes.symbolSc,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.reelsBackBg,
  ResourceTypes.reelsBackFg,
  ResourceTypes.reelFrameBg,
  ResourceTypes.reelFrameFg,
  ResourceTypes.symbolABlur,
  ResourceTypes.symbolBBlur,
  ResourceTypes.symbolCBlur,
  ResourceTypes.symbolDBlur,
  ResourceTypes.symbolEBlur,
  ResourceTypes.symbolFBlur,
  ResourceTypes.symbolGBlur,
  ResourceTypes.symbolHBlur,
  ResourceTypes.lineShort,
  ResourceTypes.lineMedium,
  ResourceTypes.lineLarge,
  ResourceTypes.rightLineShort,
  ResourceTypes.rightLineMedium,
  ResourceTypes.rightLineLarge,
  ResourceTypes.stockA,
  ResourceTypes.stockB,
  ResourceTypes.stockC,
  ResourceTypes.stockD,
  ResourceTypes.stockE,
  ResourceTypes.stockStage,
  ResourceTypes.stockStageBack,
  ResourceTypes.messagebanner,
  ResourceTypes.bgFuji,
  ResourceTypes.fgFuji,
  ResourceTypes.introBg,
  ResourceTypes.introBgPortrait,
  ResourceTypes.intro01,
  ResourceTypes.intro02,
  ResourceTypes.intro03,
  ResourceTypes.buttonOk,
  ResourceTypes.textLogo,
  ResourceTypes.titlelogo,
  ResourceTypes.buyFeatureBtn,
  ResourceTypes.buyFeatureBtnNotActive,
  ResourceTypes.buyFeatureBtnHover,
  ResourceTypes.buyFeatureBtnPressed,
  ResourceTypes.buyFeatureInput,
  ResourceTypes.buyFeatureMinusBtnDisable,
  ResourceTypes.buyFeatureMinusBtn,
  ResourceTypes.buyFeatureMinusBtnHover,
  ResourceTypes.buyFeatureMinusBtnPressed,
  ResourceTypes.buyFeaturePlusBtnDisable,
  ResourceTypes.buyFeaturePlusBtn,
  ResourceTypes.buyFeaturePlusBtnHover,
  ResourceTypes.buyFeaturePlusBtnPressed,
  ResourceTypes.buyFeatureOkBtn,
  ResourceTypes.buyFeatureOkBtnDisable,
  ResourceTypes.buyFeatureOkBtnHover,
  ResourceTypes.buyFeatureOkBtnPressed,
  ResourceTypes.buyFeatureCancelBtn,
  ResourceTypes.buyFeatureCancelBtnHover,
  ResourceTypes.buyFeatureCancelBtnPressed,
  ResourceTypes.buyFeaturePopup,
  ResourceTypes.buyFeaturePopupConfirm,
  ResourceTypes.backdrop,
  ResourceTypes.tomoe,
  ResourceTypes.particle,
  ResourceTypes.sparks,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};

export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'Nearmiss',
      url: generateTexturePath('/animations', 'Nearmiss/Nearmiss.json'),
    },
    {
      name: 'Ambient_Cloud_BG',
      url: generateTexturePath('/animations', 'ambient_cloud_bg/ambient_cloud_bg.json'),
    },
    {
      name: 'Ambient_Cloud_FG',
      url: generateTexturePath('/animations', 'ambient_cloud_fg/ambient_cloud_fg.json'),
    },
    {
      name: 'Fujin',
      url: generateTexturePath('/animations', 'fujin/fujin.json'),
    },
    {
      name: 'Fujin_Effect',
      url: generateTexturePath('/animations', 'fujin_attack_effect/fujin_attack_effect.json'),
    },
    {
      name: 'Raijin',
      url: generateTexturePath('/animations', 'raijin/raijin.json'),
    },
    {
      name: 'Raijin_Effect',
      url: generateTexturePath('/animations', 'raijin_attack_effect/raijin_attack_effect.json'),
    },
    {
      name: 'effect_frame_nearmiss',
      url: generateTexturePath('/animations', 'effect_frame_nearmiss/effect_frame_nearmiss.json'),
    },
    {
      name: 'sixth_reel',
      url: generateTexturePath('/animations', 'sixth_reel_all/sixth_reel_all.json'),
    },
    {
      name: 'sixth_reel_gear',
      url: generateTexturePath('/animations', 'sixth_reel_gear/sixth_reel_gear.json'),
    },
    {
      name: 'sixth_reel_pinwheel',
      url: generateTexturePath('/animations', 'sixth_reel_pinwheel/sixth_reel_pinwheel.json'),
    },
    {
      name: 'symbol_all',
      url: generateTexturePath('/animations', 'symbol_all/symbol_all.json', isMobile),
    },
    {
      name: 'feature_stock',
      url: generateTexturePath('/animations', 'feature_stock/feature_stock.json'),
    },
    {
      name: 'symbol_frame_payline_light',
      url: generateTexturePath('/animations', 'symbol_frame_payline_light/symbol_frame_payline_light.json'),
    },
    {
      name: 'feature_stock_light',
      url: generateTexturePath('/animations', 'feature_stock_light/feature_stock_light.json'),
    },
    {
      name: 'feature_stock_arrival',
      url: generateTexturePath('/animations', 'feature_stock_arrival/feature_stock_arrival.json'),
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'coinsAnimation',
    url: '/animations/desktop/coins/coins.json',
  },
  {
    name: 'symbolWinAnimation_A',
    url: '/animations/desktop/symbol_win/A_anima.json',
  },
  {
    name: 'symbolWinAnimation_B',
    url: '/animations/desktop/symbol_win/B_anima.json',
  },
  {
    name: 'symbolWinAnimation_C',
    url: '/animations/desktop/symbol_win/C_anima.json',
  },
  {
    name: 'symbolWinAnimation_D',
    url: '/animations/desktop/symbol_win/D_anima.json',
  },
  {
    name: 'symbolWinAnimation_E',
    url: '/animations/desktop/symbol_win/E_anima.json',
  },
  {
    name: 'symbolWinAnimation_F',
    url: '/animations/desktop/symbol_win/F_anima.json',
  },
  {
    name: 'symbolWinAnimation_G',
    url: '/animations/desktop/symbol_win/G_anima.json',
  },
  {
    name: 'symbolWinAnimation_H',
    url: '/animations/desktop/symbol_win/H_anima.json',
  },
  {
    name: 'symbolWinAnimation_SC',
    url: '/animations/desktop/symbol_win/SC_anima.json',
  },
  {
    name: 'symbolWinAnimation_WL',
    url: '/animations/desktop/symbol_win/WL_anima.json',
  },
  {
    name: 'ui',
    url: '/images/ui/ui.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
