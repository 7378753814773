import { GameMode } from '../../global.d';

import { BackGroundSkin } from './background';

export const BACKGROUND_SIZE_WIDTH = 1920;
export const BACKGROUND_SIZE_HEIGHT = 1080;
export const BACKGROUND_BASE_GAME_GRADIENT = ['#3C466E', '#5787B8', '#6E96BE', '#FFFFFF'];
export const BACKGROUND_FREE_SPINS_GRADIENT = ['#2b2b2b', '#606060', '#585858', '#f0f0f0'];

export const backGroundGameModeSkins: Record<GameMode, BackGroundSkin> = {
  [GameMode.REGULAR]: 'base',
  [GameMode.BUY_FEATURE]: 'base',
  [GameMode.FREE_SPINS]: 'freeSpin',
};
