import { TextField } from '../components/TextField';
import { UiViewContainer } from '../components/uiContainer';
import { clockTextStyle } from '../config';

class Clock extends UiViewContainer {
  private clock: TextField;

  constructor() {
    super();
    this.clock = this.initClock();
    this.addChild(this.clock.getText());
    this.getTime();
    setInterval(() => this.clock.setText(this.getTime()), 1000);
  }

  private getTime() {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const nHours = hours < 10 ? `0${hours}` : hours;
    const nMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${nHours}:${nMinutes}`;
  }

  private initClock() {
    const text = new TextField(this.getTime(), 200, 50, clockTextStyle);
    text.text.anchor.set(0, 1);

    return text;
  }

  public update(width: number, height: number) {
    this.clock.update(width, height);
  }
}

export default Clock;
