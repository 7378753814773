import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../resources.d';
import { POSITION, REELS_AMOUNT, REEL_HEIGHT } from '../config';

class Line extends PIXI.Container {
  private id: number;

  private data: number[];

  constructor(id: number, data: number[], isRightToLeft = false) {
    super();
    this.id = id;
    this.data = data;
    for (let i = 1; i < REELS_AMOUNT; i++) {
      const adjacent = POSITION[this.data[i]!]!.x - POSITION[this.data[i - 1]!]!.x;
      const opposite = POSITION[this.data[i]!]!.y - POSITION[this.data[i - 1]!]!.y;
      const angle = Math.atan(opposite / adjacent);
      const width = Math.sqrt(adjacent * adjacent + opposite * opposite);

      let sprite = new PIXI.Sprite(
        PIXI.Texture.from(isRightToLeft ? ResourceTypes.rightLineShort : ResourceTypes.lineShort),
      );
      if (Math.abs(opposite) === REEL_HEIGHT) {
        sprite = new PIXI.Sprite(
          PIXI.Texture.from(isRightToLeft ? ResourceTypes.rightLineMedium : ResourceTypes.lineMedium),
        );
      }
      if (Math.abs(opposite) === 2 * REEL_HEIGHT) {
        sprite = new PIXI.Sprite(
          PIXI.Texture.from(isRightToLeft ? ResourceTypes.rightLineLarge : ResourceTypes.lineLarge),
        );
      }
      const ratio = width / sprite.width;
      sprite.scale.set(ratio, ratio);

      if (isRightToLeft) {
        sprite.x = POSITION[this.data[i]!]!.x;
        sprite.y = POSITION[this.data[i]!]!.y;
      } else {
        sprite.x = POSITION[this.data[i - 1]!]!.x;
        sprite.y = POSITION[this.data[i - 1]!]!.y;
      }

      sprite.pivot.set(0, sprite.height / 2);
      sprite.rotation = angle;
      this.addChild(sprite);
    }
    this.visible = false;
  }
}

export default Line;
