import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../../global.d';
import ViewContainer from '../../components/container';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, eventManager } from '../../config';

class SixthReelPinWheel extends ViewContainer {
  private spine = new Spine(PIXI.Loader.shared.resources['sixth_reel_pinwheel']!.spineData!);

  constructor() {
    super();
    this.spine.position.set(GAME_CONTAINER_WIDTH, -GAME_CONTAINER_HEIGHT / 2 + 150);
    this.spine.state.setAnimation(0, 'sixth_reel_pinwheel_stop', false);
    this.addChild(this.spine);

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.spine.state.setAnimation(0, 'sixth_reel_pinwheel_stop', false);
    });

    eventManager.addListener(EventTypes.FUJIN_ATTACK, () => {
      this.spine.state.setAnimation(0, 'sixth_reel_pinwheel_spin', false);
      this.spine.state.addAnimation(0, 'sixth_reel_pinwheel_stop', false, 0);
    });

    eventManager.addListener(EventTypes.ROLLBACK_REELS, () => {
      this.spine.state.setAnimation(0, 'sixth_reel_pinwheel_stop', false);
    });
  }
}

export default SixthReelPinWheel;
