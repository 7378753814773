import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { introContents } from '../../config/introContents';
import { EventTypes } from '../../global.d';
import { setIsSoundOn, setProgress } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { BgmControl } from '../../slotMachine/bgmControl/bgmControl';
import { eventManager } from '../../slotMachine/config';
import SoundButton from '../../slotMachine/controlButtons/soundBtn';
import { UiButton } from '../../slotMachine/ui/uiButton';
import { calcPercentage, getCssVariable } from '../../utils';

import Carousel from './Carousel/index';

class IntroScreen {
  private readonly application: PIXI.Application;

  private container: PIXI.Container;

  private controlsContainer: PIXI.Container;

  private static introScreen: IntroScreen;

  public static initIntroScreen = (application: PIXI.Application): void => {
    IntroScreen.introScreen = new IntroScreen(application);
  };

  public static getInstance = (): IntroScreen => IntroScreen.introScreen;

  private background = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.introBg));

  private backgroundContainer = new PIXI.Container();

  private playBtn: UiButton;

  private soundBtn: SoundButton;

  private carousel: Carousel;

  private bindedResize = this.resize.bind(this);

  private constructor(application: PIXI.Application) {
    this.application = application;
    this.background.anchor.set(0.5);
    this.backgroundContainer.addChild(this.background);
    this.application.stage.addChild(this.backgroundContainer);
    this.container = new PIXI.Container();
    this.controlsContainer = new PIXI.Container();
    this.carousel = new Carousel(introContents);
    this.container.addChild(this.carousel);
    this.playBtn = this.initPlayButton();
    this.soundBtn = new SoundButton();
    this.controlsContainer.addChild(this.playBtn, this.soundBtn);
    this.container.addChild(this.controlsContainer);
    this.application.stage.addChild(this.container);
    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);

    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      AudioApi.setSoundState(setIsSoundOn());
    });
  }

  private initPlayButton(): UiButton {
    const playButton = new UiButton('play');
    playButton.buttonSprite.anchor.set(1, 0.5);
    playButton.interactive = true;

    const clickCallback = () => {
      BgmControl.handleChangeRestriction();

      setProgress({ ...setProgress(), wasLoaded: true });

      setTimeout(() => {
        this.destroy();
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
    };
    playButton.on('click', clickCallback);
    playButton.on('touchend', clickCallback);

    return playButton;
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backgroundContainer.scale.set(height / this.background.height);
    } else {
      this.backgroundContainer.scale.set(width / this.background.width);
    }
  };

  private resize(width: number, height: number): void {
    const isPortraitMode = width < height;
    const gap = calcPercentage(width, 1.25);
    const playBtnGap = calcPercentage(width, 5);
    this.background.texture = PIXI.Texture.from(isPortraitMode ? ResourceTypes.introBgPortrait : ResourceTypes.introBg);
    this.setBgSize(width, height);
    const heightWithBottomGap = isPortraitMode
      ? calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-portrait'), 10))
      : calcPercentage(height, 100 - parseInt(getCssVariable('--bottom-height-percent-landscape'), 10));

    this.playBtn.buttonSprite.anchor.set(1, 1);
    this.playBtn.x = width - playBtnGap;
    this.playBtn.y = height - this.playBtn.height / 2;
    this.playBtn.setSize(calcPercentage(width, 20));

    this.soundBtn.buttonSprite.anchor.set(0, 0);
    this.soundBtn.x = gap;
    this.soundBtn.y = gap;
    this.soundBtn.setSize(calcPercentage(width, 7.5));

    this.carousel.setSize(width, heightWithBottomGap, height - heightWithBottomGap);

    if (isPortraitMode) {
      this.soundBtn.buttonSprite.anchor.set(1, 0);
      this.soundBtn.x = width - gap;
      this.soundBtn.y = gap;
      this.soundBtn.setSize(calcPercentage(height, 7.5));

      this.playBtn.setSize(calcPercentage(width, 40));
      this.playBtn.buttonSprite.anchor.set(0.5, 0);
      this.playBtn.y = this.carousel.getBounds().y + this.carousel.height + 10;
      this.playBtn.x = width / 2;
    }

    this.application.renderer.resize(width, height);
  }

  private destroy(): void {
    this.container.destroy();
    this.background.destroy();
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
  }
}

export default IntroScreen;
